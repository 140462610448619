<template>
  <v-card class="p-4" v-resize="setContentHeight">
    <v-card-title>
      <!-- ダイアログタイトル -->
      <div>メンバー割当</div>

      <v-spacer></v-spacer>

      <!-- 閉じるボタン -->
      <v-btn icon @click="$emit('close')">
        <v-icon large color="primary">mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>

    <!-- 検索フォーム -->
    <div class="px-4 h-16 flex items-center space-x-8">
      <!-- 病院名 -->
      <div v-if="hasMultiHospitals" class="flex items-center space-x-4">
        <div>病院名</div>
        <div class="w-64">
          <v-select
            v-model="searchHospitalIds"
            :items="selfUserHospitals"
            item-value="id"
            item-text="name"
            multiple
            outlined
            dense
            hide-details
          >
          </v-select>
        </div>
      </div>

      <!-- キーワード -->
      <div class="flex items-center space-x-4">
        <div>キーワード</div>
        <div class="w-64">
          <v-text-field
            v-model="searchKeyword"
            placeholder="氏名・氏名（かな）"
            autofocus
            outlined
            dense
            hide-details
          >
            <v-icon slot="append" @click="clearKeyword">mdi-close</v-icon>
          </v-text-field>
        </div>
      </div>
    </div>

    <!-- メンバー候補一覧 -->
    <div class="flex items-center justify-space-between mb-4">
      <div>全{{ memberCandidates.length }}件中/{{ filteredMemberLength }}件を表示</div>

      <v-btn color="primary" elevation="0" @click="showMemberDialog()">メンバー登録</v-btn>
    </div>

    <member-list
      ref="refsample"
      :key="memberListUpdateKey"
      :patientUid="patientUid"
      :searchHospitalIds="searchHospitalIds"
      :searchKeyword="searchKeyword"
      :displayMode="DIALOG_DISPLAY_MODE.UPDATE"
      @update:filteredMemberLength="setFilteredMemberLength"
    >
    </member-list>

    <!-- ボタンエリア -->
    <div class="flex justify-end space-x-4">
      <!-- キャンセルボタン -->
      <v-btn elevation="0" @click="$emit('close')">キャンセル</v-btn>

      <!-- 更新ボタン -->
      <v-btn elevation="0" color="primary" @click="assignMembersToPatient" :disabled="isProcessing">
        更新
      </v-btn>
    </div>

    <!-- メンバー登録ダイアログ -->
    <v-dialog
      v-if="memberDialog.show"
      v-model="memberDialog.show"
      width="800"
      persistent
      no-click-animation
    >
      <member-dialog
        v-bind="memberDialog.params"
        @complete="completeMemberRegister"
        @close="memberDialog.show = false"
      >
      </member-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import MemberDialog from "@/components/dialogs/Member";
import MemberList from "@/components/organisms/MemberList";
import { DIALOG_DISPLAY_MODE, GENDER_LABEL } from "@/const/const";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapState } from "vuex";
export default {
  name: "MemberAssignDialog",
  components: { MemberList, MemberDialog },
  mixins: [errorHandlerMixin()],
  props: {
    // 患者UID
    patientUid: {
      required: true,
    },
  },
  data: () => ({
    // コンテンツの高さ
    contentHeight: 0,

    // 性別のラベル
    GENDER_LABEL,

    // ヘッダー
    headers: [
      { text: "病院名", value: "hospitalNames" },
      { text: "氏名", value: "name" },
      { text: "氏名（かな）", value: "nameKana" },
      { text: "生年月日", value: "dateOfBirth" },
      { text: "性別", value: "gender" },
    ],

    // 検索する病院
    searchHospitalIds: [],
    // 検索キーワード
    searchKeyword: "",
    // 表示モード
    DIALOG_DISPLAY_MODE,
    // 検索結果の件数
    filteredMemberLength: 0,
    // メンバー登録ダイアログの表示の有無
    memberDialog: {
      show: false,
    },
    // メンバー一覧を任意のタイミングで更新するためのキー
    memberListUpdateKey: 1,
    // 処理中フラグ
    isProcessing: false,
  }),
  watch: {
    memberCandidates() {
      // メンバー一覧を更新
      this.memberListUpdateKey++;
    },
  },
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // メンバー情報
    ...mapState("member", ["members"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    ...mapState("patient", ["patients"]),

    // 患者情報
    patient() {
      return this.getPatient(this.patientUid);
    },
    // 複数病院に所属しているかどうか
    hasMultiHospitals() {
      return this.selfUser?.hospitalIds.length > 1;
    },
    // ログインユーザの所属病院情報取得
    selfUserHospitals() {
      return this.selfUser.hospitalIds.map((hospitalId) =>
        this.hospitals.find((hospital) => hospital.id == hospitalId)
      );
    },
    // メンバー候補
    memberCandidates() {
      const memberCandidates = [...this.patients, ...this.members];
      // 患者自身を除外する
      return memberCandidates.filter((memberCandidate) => memberCandidate.uid != this.patientUid);
    },
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // コンテンツの高さを設定
    setContentHeight() {
      this.contentHeight = window.innerHeight - 380;
    },

    // 患者情報を取得
    getPatient(uid) {
      return this.patients.find((patient) => patient.uid == uid);
    },

    // メンバー割当更新
    async assignMembersToPatient() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        const { uid } = this.patient;
        // 選択したメンバーのuid
        const memberUids = this.$refs.refsample["selectedMembers"].map((select) => select.uid);

        // メンバーを更新
        const assignMembersToPatient = this.$httpsCallable(
          this.$functions,
          "assignmemberstopatient"
        );

        await assignMembersToPatient({ uid, memberUids });

        this.decrementRunningApiCount();

        this.$store.dispatch("snackbar/openSnackbar", {
          text: `メンバー割当を更新しました`,
          color: "success",
        });

        this.$emit("complete");
      } catch (error) {
        this.showError(`メンバー割当の更新に失敗しました`);
      } finally {
        this.isProcessing = false;
      }
    },
    // フィルタされたメンバーの件数を設定
    setFilteredMemberLength(filteredMemberLength) {
      this.filteredMemberLength = filteredMemberLength;
    },
    // 検索キーワードのクリア
    clearKeyword() {
      this.$data.searchKeyword = "";
    },

    // メンバー登録ダイアログを表示
    showMemberDialog() {
      this.memberDialog.params = {
        displayMode: DIALOG_DISPLAY_MODE.REGISTER,
      };
      this.memberDialog.show = true;
    },

    // メンバー登録を完了
    completeMemberRegister() {
      this.memberDialog.show = false;
    },
  },
};
</script>
