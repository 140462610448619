const getters = {};

const state = {
  // 購読解除一覧
  unsubscribes: [],
};

const mutations = {
  // 購読解除を追加
  addUnsubscribe: (state, { unsubscribe }) => {
    state.unsubscribes.push(unsubscribe);
  },
};

const actions = {
  addUnsubscribe: ({ commit }, { unsubscribe }) => {
    commit("addUnsubscribe", { unsubscribe });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
