<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Dialog @close="$emit('close')">
      <!-- タイトル -->
      <template #[`title`]>
        {{ title }}
      </template>

      <!-- ヘッダーボタン -->
      <template #[`header-buttons`]>
        <!-- 編集ボタン -->
        <v-btn v-if="readonly" icon large @click="showUpdateDoctorDialog">
          <v-icon large color="primary">mdi-pencil</v-icon>
        </v-btn>
      </template>

      <!-- コンテンツ -->
      <template #[`contents`]>
        <!-- 病院名 -->
        <div v-if="selfUser?.hospitalIds.length > 1" class="flex items-center">
          <div class="w-64 pb-6">病院名<span v-if="!readonly" class="ml-1 red--text">*</span></div>
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="病院名" rules="required">
              <v-text-field v-if="readonly" :value="hospitalNames" dense outlined disabled>
              </v-text-field>
              <v-select
                v-else
                v-model="localDoctor.hospitalIds"
                :items="hospitals"
                item-value="id"
                item-text="name"
                multiple
                dense
                outlined
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名 -->
        <div class="flex items-center">
          <div class="w-64 pb-6">氏名<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <!-- 姓 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="姓" rules="required|max:10">
              <v-text-field
                v-model="localDoctor.lastName"
                class="mr-4"
                placeholder="姓"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 名 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="名" rules="required|max:10">
              <v-text-field
                v-model="localDoctor.firstName"
                placeholder="名"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名（かな） -->
        <div class="flex items-center">
          <div class="w-64 pb-6">
            氏名（かな）<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>

          <!-- 姓（かな） -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="せい" rules="required|hiragana|max:20">
              <v-text-field
                v-model="localDoctor.lastNameKana"
                class="mr-4"
                placeholder="せい"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 名（かな） -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="めい" rules="required|hiragana|max:20">
              <v-text-field
                v-model="localDoctor.firstNameKana"
                placeholder="めい"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 性別 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">性別<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <validation-provider v-slot="{ errors }" name="性別">
            <v-text-field
              v-if="readonly"
              :value="GENDER_LABEL[localDoctor.gender]"
              dense
              outlined
              disabled
            >
            </v-text-field>
            <v-radio-group
              v-else
              class="mt-0"
              v-model="localDoctor.gender"
              row
              :error-messages="errors"
            >
              <v-radio
                v-for="gender in GENDERS"
                :key="gender.value"
                :label="gender.label"
                :value="gender.value"
              >
              </v-radio>
            </v-radio-group>
          </validation-provider>
        </div>

        <!-- メールアドレス -->
        <div class="flex items-center">
          <div class="w-64 pb-6">
            メールアドレス<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="メールアドレス" rules="required|email">
              <v-text-field
                v-model="localDoctor.email"
                type="email"
                dense
                autoComplete="new-email"
                outlined
                :disabled="localDisplayMode != DIALOG_DISPLAY_MODE.REGISTER"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <div v-if="localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER">
          <!-- パスワード -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード<span v-if="!readonly" class="ml-1 red--text">*</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <div>
                  パスワードは以下を満たす必要があります
                  <ul class="list-disc">
                    <li>{{ PASSWORD_MIN_LENGTH }}文字以上</li>
                    <li>英字・数字・記号を含む</li>
                    <li>使用可能な記号{{ PASSWORD_ENABLE_SYMBOLS }}</li>
                  </ul>
                </div>
              </v-tooltip>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="パスワード" rules="required|password">
                <v-text-field
                  v-model="localDoctor.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  dense
                  autoComplete="new-password"
                  solo
                  flat
                  outlined
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- パスワード（確認用） -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード（確認用）<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider
                v-slot="{ errors }"
                name="パスワード（確認用）"
                :rules="`required|password|password_confirm:${localDoctor.password}`"
              >
                <v-text-field
                  v-model="localDoctor.passwordConfirm"
                  :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordConfirm ? 'text' : 'password'"
                  dense
                  outlined
                  :error-messages="errors"
                  @click:append="showPasswordConfirm = !showPasswordConfirm"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>
      </template>

      <!-- フッターボタン -->
      <template #[`footer-buttons`]>
        <div v-if="localDisplayMode == DIALOG_DISPLAY_MODE.UPDATE">
          <v-btn elevation="0" @click="changeDisplayModeDetail">
            <div>キャンセル</div>
          </v-btn>
        </div>
        <div v-else>
          <v-btn elevation="0" @click="$emit('close')">
            <div>
              {{ localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL ? "閉じる" : "キャンセル" }}
            </div>
          </v-btn>
        </div>

        <!-- 登録ボタン -->
        <v-btn
          v-if="localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="registerDoctor"
        >
          登録
        </v-btn>

        <!-- 更新ボタン -->
        <v-btn
          v-if="localDisplayMode == DIALOG_DISPLAY_MODE.UPDATE"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="updateDoctor"
        >
          更新
        </v-btn>
      </template>
    </Dialog>
  </validation-observer>
</template>

<script>
import Dialog from "@/components/templates/Dialog";
import {
  DIALOG_DISPLAY_MODE,
  GENDER,
  GENDER_LABEL,
  GENDERS,
  PASSWORD_ENABLE_SYMBOLS,
  PASSWORD_MIN_LENGTH,
} from "@/const/const";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { mapActions, mapState } from "vuex";

export default {
  name: "DoctorDialog",
  components: {
    Dialog,
  },
  mixins: [errorHandlerMixin()],
  props: {
    // ダイアログ表示モード
    displayMode: {
      required: true,
    },
    // 医師情報
    doctor: {
      default: null,
    },
  },
  data: () => ({
    PASSWORD_MIN_LENGTH,
    PASSWORD_ENABLE_SYMBOLS,
    // 表示モード
    DIALOG_DISPLAY_MODE,
    // 性別
    GENDERS,
    // 性別のラベル
    GENDER_LABEL,
    // ダイアログ表示モード
    localDisplayMode: null,
    // 医師情報
    localDoctor: {
      // 病院名
      hospitalIds: [],
      // 姓
      lastName: "",
      // 名
      firstName: "",
      // 姓(かな)
      lastNameKana: "",
      // 名(かな)
      firstNameKana: "",
      // 性別
      gender: GENDER.UNSELECTED,
      // uid
      uid: "",
      // メールアドレス
      email: "",
      // パスワード
      password: "",
      // パスワード（確認用）
      passwordConfirm: "",
    },
    // パスワード表示制御
    showPassword: false,
    showPasswordConfirm: false,
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // ログインユーザー
    ...mapState("user", ["selfUser"]),
    // 病院名情報
    ...mapState("hospital", ["hospitals"]),

    // タイトル
    title() {
      let suffix = "";

      switch (this.localDisplayMode) {
        case DIALOG_DISPLAY_MODE.REGISTER:
          suffix = "登録";
          break;
        case DIALOG_DISPLAY_MODE.UPDATE:
          suffix = "編集";
          break;
        case DIALOG_DISPLAY_MODE.DETAIL:
          suffix = "詳細";
          break;
      }
      return `医師${suffix}`;
    },

    // 病院名表示
    hospitalNames() {
      return this.hospitals
        .filter((hospital) => this.doctor.hospitalIds.includes(hospital.id))
        .map((hospital) => hospital.name)
        .join(", ");
    },

    // 読み取り専用か
    readonly() {
      return this.localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL;
    },
  },
  created() {
    this.localDisplayMode = this.displayMode;
    // 登録
    if (this.localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER) {
      // ログインユーザーの所属病院名が１つの場合
      if (this.selfUser.hospitalIds.length == 1) {
        // 病院名にデフォルト値を設定
        this.localDoctor.hospitalIds.push(this.selfUser.hospitalIds[0]);
      }
    }
    // 詳細
    if (this.localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL) {
      this.localDoctor = { ...this.doctor };
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // 医師登録
    async registerDoctor() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // 医師を追加
        const registerDoctorFunction = this.$httpsCallable(this.$functions, "registerdoctor");
        await registerDoctorFunction(this.localDoctor);

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("complete");
      } catch (error) {
        if (error.message === "auth/already-exists") {
          this.showError("メールアドレスが登録済みです");
        } else if (error.message === "auth/invalid-password") {
          this.showError("パスワードの形式が不正です");
        } else {
          this.showError(
            `医師情報（${this.localDoctor.lastName} ${this.localDoctor.firstName}）の登録に失敗しました`
          );
        }
      } finally {
        this.isProcessing = false;
      }
    },
    // 表示を編集モードに切り替える
    showUpdateDoctorDialog() {
      this.localDisplayMode = DIALOG_DISPLAY_MODE.UPDATE;
    },
    // 医師情報更新
    async updateDoctor() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        const { uid, hospitalIds, firstName, lastName, firstNameKana, lastNameKana, gender } =
          this.localDoctor;
        const doctorDoc = await doc(this.$firestore, "users", uid);

        // 医師情報を更新
        await updateDoc(doctorDoc, {
          hospitalIds,
          firstName,
          lastName,
          firstNameKana,
          lastNameKana,
          gender,
          updated: serverTimestamp(),
          updatedUserId: this.selfUser.uid,
        });

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("close");
      } catch (error) {
        this.showError(
          `医師情報（${this.localDoctor.lastName} ${this.localDoctor.firstName}）の更新に失敗しました`
        );
      } finally {
        this.isProcessing = false;
      }
    },
    // 成功
    showSuccess() {
      const action = this.localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER ? "登録" : "更新";
      this.$store.dispatch("snackbar/openSnackbar", {
        text: `医師情報（${this.localDoctor.lastName} ${this.localDoctor.firstName}）を${action}しました`,
        color: "success",
      });
    },
    // 表示を詳細モードに切り替える
    changeDisplayModeDetail() {
      this.localDoctor = { ...this.doctor };
      this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;
    },
  },
};
</script>
