<template>
  <div class="h-full" v-resize="setDoctorListHeight">
    <v-container class="px-0 space-y-2">
      <!-- フィルター -->
      <div>
        <v-card class="py-4" outlined>
          <div class="flex items-center px-4 gap-x-8 gap-y-4 flex-wrap">
            <!-- 病院名 -->
            <div v-if="showHospital">
              <div class="flex items-center space-x-4">
                <div>病院名</div>
                <div class="w-80">
                  <v-select
                    v-model="filterCondition.hospitalIds"
                    :items="hospitals"
                    item-value="id"
                    item-text="name"
                    multiple
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </div>
              </div>
            </div>

            <!-- 氏名 -->
            <div>
              <div class="flex items-center space-x-4">
                <div>氏名</div>
                <div class="w-80">
                  <v-text-field
                    v-model="filterCondition.name"
                    placeholder="氏名 または 氏名（かな）"
                    outlined
                    dense
                    hide-details
                    clearable
                  >
                  </v-text-field>
                </div>
              </div>
            </div>

            <v-spacer></v-spacer>

            <!-- リセット -->
            <v-btn color="primary" elevation="0" outlined @click="resetFilterCondition">
              リセット
            </v-btn>
          </div>
        </v-card>
      </div>

      <!-- 医師一覧 -->
      <div class="flex items-center justify-space-between">
        <!-- 表示件数 -->
        <div>全{{ doctors.length }}件中/{{ filteredDoctors.length }}件を表示</div>

        <!-- 医師登録ボタン -->
        <v-btn color="primary" elevation="0" @click="clickRegisterDoctor">医師登録</v-btn>
      </div>

      <div class="w-full">
        <v-data-table
          ref="doctor-list"
          :headers="headers"
          :items="filteredDoctors"
          :items-per-page="-1"
          :height="doctorListHeight"
          hide-default-footer
          sort-by="nameKana"
          fixed-header
          @click:row="
            (doctor) => {
              showDoctorDetailDialog(doctor);
            }
          "
        >
        </v-data-table>
      </div>
      <!-- 医師ダイアログ -->
      <v-dialog
        v-if="doctorDialog.show"
        v-model="doctorDialog.show"
        width="800"
        persistent
        no-click-animation
      >
        <doctor-dialog
          v-bind="doctorDialog.params"
          @complete="doctorDialog.show = false"
          @close="doctorDialog.show = false"
        ></doctor-dialog>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Doctor from "@/components/dialogs/Doctor";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import { mapState } from "vuex";
export default {
  name: "DoctorList",
  components: { DoctorDialog: Doctor },
  data: () => ({
    // 医師一覧の高さ
    doctorListHeight: "",
    // フィルター条件
    filterCondition: {
      // NOTE: 初期値は resetFilterCondition に定義すること
    },
    // 医師ダイアログ
    doctorDialog: {
      show: false,
      params: {},
    },
    // 表示モード
    DIALOG_DISPLAY_MODE,
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 医師情報
    ...mapState("doctor", ["doctors"]),
    // フィルター済みの医師情報
    filteredDoctors() {
      // 一覧の表示形式に合わせて整形
      let filteredDoctors = this.doctors.map((doctor) => {
        const hospitalNames = doctor.hospitalIds.map((hospitalId) => {
          const hospital = this.hospitals.find((hospital) => hospital.id == hospitalId);
          return hospital?.name || "";
        });
        return {
          ...doctor,
          hospitalNames: hospitalNames,
          name: `${doctor.lastName} ${doctor.firstName}`,
          nameKana: `${doctor.lastNameKana} ${doctor.firstNameKana}`,
        };
      });

      // フィルター
      const { hospitalIds, name } = this.filterCondition;

      // 病院
      if (hospitalIds?.length > 0) {
        filteredDoctors = filteredDoctors.filter((filteredDoctor) =>
          filteredDoctor.hospitalIds.some((hospitalId) => hospitalIds.includes(hospitalId))
        );
      }

      // 氏名・かな
      if (name) {
        filteredDoctors = filteredDoctors.filter(
          (filteredDoctor) =>
            filteredDoctor.name.includes(name) || filteredDoctor.nameKana.includes(name)
        );
      }

      return filteredDoctors;
    },
    // headerの表示
    headers() {
      const headers = [
        { text: "氏名", value: "name", width: "50%" },
        { text: "氏名（かな）", value: "nameKana", width: "50%" },
      ];
      // 病院名列はユーザーが複数の病院に所属している場合のみ表示
      if (this.showHospital) {
        headers.unshift({ text: "病院名", value: "hospitalNames" });
      }
      return headers;
    },
    // 病院を表示するかどうか
    showHospital() {
      // ユーザーが複数の病院に所属しているかどうか
      return this.selfUser?.hospitalIds.length > 1;
    },
  },
  mounted() {
    // フィルター条件をリセット
    this.resetFilterCondition();
  },
  methods: {
    // 医師一覧の高さを設定
    setDoctorListHeight() {
      this.doctorListHeight =
        window.innerHeight - this.$refs["doctor-list"].$el.getBoundingClientRect().top - 70;
    },
    // 医師登録ボタン押下
    clickRegisterDoctor() {
      this.doctorDialog.params.displayMode = DIALOG_DISPLAY_MODE.REGISTER;
      this.doctorDialog.show = true;
    },
    // クリックした行のdoctorを渡し、医師詳細ダイアログを表示
    showDoctorDetailDialog(doctor) {
      this.doctorDialog.params = {
        doctor,
        displayMode: DIALOG_DISPLAY_MODE.DETAIL,
      };
      this.doctorDialog.show = true;
    },

    // フィルター条件をリセット
    resetFilterCondition() {
      this.filterCondition = {
        // 病院
        hospitalIds: [],
        // 氏名・かな
        name: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
// 医師一覧
.v-data-table {
  @apply border;
}

// 一覧の行
::v-deep tbody > tr > td {
  @apply cursor-pointer;
}

::v-deep tbody > tr:last-child > td {
  @apply border-b;
  @apply truncate;
}
</style>
