const getters = {};

const getDefaultState = () => {
  return {
    // 病院情報
    hospitals: [],
  };
};

const state = getDefaultState();

const mutations = {
  // 病院情報を追加
  addHospital: (state, payload) => {
    const index = state.hospitals.findIndex((hospital) => hospital.id == payload.id);
    if (index == -1) {
      state.hospitals.push(payload);
    } else {
      state.hospitals.splice(index, 1, payload);
    }
  },

  // 病院情報を削除
  deleteHospital: (state, payload) =>{
    const index = state.hospitals.findIndex((hospital) => hospital.id == payload.id);
    if (index != -1) {
      state.hospitals.splice(index, 1);
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addHospital: ({ commit }, hospital) => {
    commit("addHospital", hospital);
  },
  deleteHospital: ({ commit }, hospital) => {
    commit("deleteHospital", hospital);
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
