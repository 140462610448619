<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card class="p-4 space-y-4">
      <v-card-title>
        <!-- ダイアログタイトル -->
        <div>パスワード変更</div>

        <v-spacer></v-spacer>

        <!-- 閉じるボタン -->
        <v-btn icon large @click="$emit('close')">
          <v-icon large color="primary">mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <!-- 現在のパスワード -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">現在のパスワード<span class="ml-1 red--text">*</span></div>
        <div class="flex-auto">
          <validation-provider v-slot="{ errors }" name="現在のパスワード" rules="required">
            <v-text-field
              v-model="currentPassword"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              outlined
              dense
              :error-messages="errors"
              @click:append="showCurrentPassword = !showCurrentPassword"
            >
            </v-text-field>
          </validation-provider>
        </div>
      </div>

      <!-- 新しいパスワード -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">
          新しいパスワード<span class="ml-1 red--text">*</span>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
            </template>
            <div>
              パスワードは以下を満たす必要があります
              <ul class="list-disc">
                <li>{{ PASSWORD_MIN_LENGTH }}文字以上</li>
                <li>英字・数字・記号を含む</li>
                <li>使用可能な記号{{ PASSWORD_ENABLE_SYMBOLS }}</li>
              </ul>
            </div>
          </v-tooltip>
        </div>
        <div class="flex-auto">
          <validation-provider
            v-slot="{ errors }"
            name="新しいパスワード"
            rules="required|password"
          >
            <v-text-field
              v-model="newPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              outlined
              dense
              :error-messages="errors"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </validation-provider>
        </div>
      </div>

      <!-- 新しいパスワード（確認用） -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">新しいパスワード（確認用）<span class="ml-1 red--text">*</span></div>
        <div class="flex-auto">
          <validation-provider
            v-slot="{ errors }"
            name="新しいパスワード（確認用）"
            :rules="`required|password_confirm:${newPassword}`"
          >
            <v-text-field
              v-model="newPasswordConfirm"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordConfirm ? 'text' : 'password'"
              outlined
              dense
              :error-messages="errors"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
            >
            </v-text-field>
          </validation-provider>
        </div>
      </div>

      <!-- 変更ボタン -->
      <div class="flex justify-end">
        <v-btn
          color="primary"
          elevation="0"
          :disabled="invalid || !(newPassword == newPasswordConfirm) || isProcessing"
          @click="passwordChange"
        >
          変更
        </v-btn>
      </div>
    </v-card>
  </validation-observer>
</template>

<script>
import { PASSWORD_ENABLE_SYMBOLS, PASSWORD_MIN_LENGTH } from "@/const/const";
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";
export default {
  name: "PasswordChange",
  mixins: [errorHandlerMixin()],
  components: {},
  data: () => ({
    PASSWORD_MIN_LENGTH,
    PASSWORD_ENABLE_SYMBOLS,
    // 現在のパスワード
    currentPassword: "",
    // 新しいパスワード
    newPassword: "",
    // 新しいパスワード（確認用）
    newPasswordConfirm: "",
    // パスワード表示制御
    showCurrentPassword: false,
    showPassword: false,
    showPasswordConfirm: false,
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    async passwordChange() {
      this.isProcessing = true;
      this.incrementRunningApiCount();

      try {
        const func = this.$httpsCallable(this.$functions, "passwordchange");
        await func({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
        });

        this.openSnackbar({ text: "パスワードを変更しました" });

        this.decrementRunningApiCount();

        this.$emit("close");
      } catch (error) {
        let message = "";
        switch (error.message) {
          case "auth/invalid-credential":
            message = MESSAGES.ERRORS.PASSWORD_WRONG;
            break;
          default:
            message = MESSAGES.ERRORS.PASSWORD_CHANGE;
        }
        this.showError(message);
        this.decrementRunningApiCount();
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
