<template>
  <v-container class="h-full flex justify-center items-center">
    <div>
      <h1 class="text-h1">404</h1>
      <h2 class="subtitle-1">
        お探しのページは一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。
        URLが正しく入力されているかご確認ください。
      </h2>
      <v-btn color="primary" @click="goTopPage">
        <v-icon>mdi-home</v-icon>
        トップページ
      </v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Error404",
  methods: {
    goTopPage() {
      this.$router.push({
        name: "/patient-list"
      })
      .catch(() => {});
    },
  }
}
</script>