<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card class="p-4 space-y-4">
      <v-card-title>
        <!-- ダイアログタイトル -->
        <div>メールアドレス変更</div>

        <v-spacer></v-spacer>

        <!-- 閉じるボタン -->
        <v-btn icon large @click="$emit('close')">
          <v-icon large color="primary">mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <!-- メッセージ -->
      <p>新しいメールアドレスを入力してください。</p>
      <p class="mt-0">新しいメールアドレス宛にメールアドレス変更コードをお送りします。</p>
      <p class="mt-0 mb-4">
        コードの有効期限は30分間となっておりますので、速やかにご入力をお願いします。
      </p>

      <!-- 現在のメールアドレス -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">現在のメールアドレス</div>
        <div class="flex-auto">
          <v-text-field class="pointer-events-none" :value="currentEmail" outlined dense readonly>
          </v-text-field>
        </div>
      </div>

      <!-- 新しいメールアドレス -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">新しいメールアドレス<span class="ml-1 red--text">*</span></div>
        <div class="flex-auto">
          <validation-provider
            v-slot="{ errors }"
            name="新しいメールアドレス"
            rules="required|email"
          >
            <v-text-field v-model="newEmail" type="email" outlined dense :error-messages="errors">
            </v-text-field>
          </validation-provider>
        </div>
      </div>

      <!-- 新しいメールアドレス（確認用） -->
      <div class="h-16 flex items-center">
        <div class="w-64 pb-6">
          新しいメールアドレス（確認用）<span class="ml-1 red--text">*</span>
        </div>
        <div class="flex-auto">
          <validation-provider
            v-slot="{ errors }"
            name="新しいメールアドレス（確認用）"
            :rules="`required|email_confirm:${newEmail}`"
          >
            <v-text-field
              v-model="newEmailConfirm"
              type="email"
              outlined
              dense
              :error-messages="errors"
            >
            </v-text-field>
          </validation-provider>
        </div>
      </div>

      <!-- 送信ボタン -->
      <div class="flex justify-end">
        <v-btn
          color="primary"
          elevation="0"
          :disabled="invalid || isProcessing"
          @click="sendEmailChangeMail"
        >
          送信
        </v-btn>
      </div>
    </v-card>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";
export default {
  name: "EmailChange",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // 新しいメールアドレス
    newEmail: "",

    // 新しいメールアドレス（確認用）
    newEmailConfirm: "",

    // メールアドレス変更ダイアログ
    emailChangeDialog: {
      show: false,
    },
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // 現在のメールアドレス
    currentEmail() {
      return this.$store.state.user.selfUser?.email;
    },
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // メールアドレス変更メール送信
    async sendEmailChangeMail() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // コードを生成し、ドキュメントのUUIDを取得
        const func = this.$httpsCallable(this.$functions, "emailchange");
        const res = await func({ newEmail: this.newEmail, newEmailConfirm: this.newEmailConfirm });

        this.decrementRunningApiCount();

        this.$emit("emailChangeCodeSent", res.data.codeId);
      } catch (err) {
        if (err.message === "auth/email-used") {
          this.showError(MESSAGES.ERRORS.EMAIL_USED);
        } else {
          this.showError(MESSAGES.ERRORS.UNEXPECTED);
        }
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>
