const getters = {};

const getDefaultState = () => {
  return {
    // 医師情報
    doctors: [],
  };
};

const state = getDefaultState();

const mutations = {
  // 医師情報を追加
  // 既に存在する場合は上書き更新
  addDoctor: (state, payload) => {
    const index = state.doctors.findIndex((doctor) => doctor.uid == payload.uid);

    if (index == -1) {
      state.doctors.push(payload);
    } else {
      state.doctors.splice(index, 1, payload);
    }
  },

  // 医師情報を削除
  deleteDoctor: (state, payload) => {
    const index = state.doctors.findIndex((doctor) => doctor.uid == payload.uid);
    if (index != -1) {
      state.doctors.splice(index, 1);
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addDoctor: ({ commit }, doctor) => {
    commit("addDoctor", doctor);
  },
  deleteDoctor: ({ commit }, doctor) => {
    commit("deleteDoctor", doctor);
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
