import { collection, onSnapshot, query, where } from "firebase/firestore";
import { mapActions, mapState } from "vuex";

export default function () {
  return {
    computed: {
      ...mapState("user", ["selfUser"]),
    },
    methods: {
      ...mapActions("doctor", ["addDoctor", "deleteDoctor"]),

      // usersから医師情報を購読
      subscribeDoctors() {
        try {
          const db = this.$firestore;

          // typeがdoctorかつログインユーザーと同じ病院に所属しているuserを取得
          const q = query(
            collection(db, "users"),
            where("type", "==", "doctor"),
            where("hospitalIds", "array-contains-any", this.selfUser.hospitalIds)
          );
          return onSnapshot(
            q,
            (snapshot) => {
              snapshot.docChanges().forEach((change) => {
                const doc = change.doc;

                switch (change.type) {
                  // 医師追加,医師更新
                  case "added":
                  case "modified":
                    this.addDoctor({ uid: doc.id, ...doc.data() });
                    break;
                  // 医師削除
                  case "removed":
                    this.deleteDoctor({ uid: doc.id });
                    break;
                }
              });
            },
            () => {
              location.reload();
            }
          );
        } catch (error) {
          // TODO: エラーハンドリング
          console.error("Firestore エラー:", error);
        }
      },
    },
  };
}
