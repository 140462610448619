const getters = {};

const getDefaultState = () => {
  return {
    // 自身のユーザー情報
    selfUser: null,

    // メンバー情報
    members: [],

    // 医師情報
    doctors: [],
  };
};

const state = getDefaultState();

const mutations = {
  // 自身のユーザー情報を設定
  setSelfUser: (state, { uid, selfUser }) => {
    state.selfUser = { uid, ...selfUser };
  },

  // メンバー情報を追加
  addMember: (state, { uid, member }) => {
    state.members.push({ uid, ...member });
  },

  // 医師情報を追加
  addDoctor: (state, { uid, doctor }) => {
    state.doctors.push({ uid, ...doctor });
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  setSelfUser: ({ commit }, { uid, selfUser }) => {
    commit("setSelfUser", { uid, selfUser });
  },
  addMember: ({ commit }, { uid, member }) => {
    commit("addMember", { uid, member });
  },
  addDoctor: ({ commit }, { uid, doctor }) => {
    commit("addDoctor", { uid, doctor });
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
