<template>
  <v-container class="px-0">
    <div class="my-2"></div>

    <!-- 各種設定 -->
    <div v-for="(menu, index) in menus" :key="index" class="cursor-pointer" @click="action(index)">
      <div class="flex px-4">
        <div class="flex justify-between cursor-pointer hover:opacity-85">
          <div>{{ menu }}</div>
          <div>
            <v-icon> mdi-chevron-right </v-icon>
          </div>
        </div>
      </div>

      <v-divider class="my-4 px-0"></v-divider>
    </div>

    <!-- アラート設定 -->
    <v-dialog
      v-if="alertSettingDialog.show"
      v-model="alertSettingDialog.show"
      :width="700"
      persistent
      no-click-animation
    >
      <alert-setting-dialog
        v-bind="alertSettingDialog.params"
        @close="alertSettingDialog.show = false"
      ></alert-setting-dialog>
    </v-dialog>

    <!-- メールアドレス変更 -->
    <v-dialog
      v-if="showEmailChangeDialog"
      v-model="showEmailChangeDialog"
      width="1000"
      persistent
      no-click-animation
    >
      <email-change
        @close="showEmailChangeDialog = false"
        @emailChangeCodeSent="openEmailChangeCodeConfirmDialog"
      ></email-change>
    </v-dialog>

    <!-- メールアドレス変更コード確認 -->
    <v-dialog
      v-if="showEmailChangeConfirmDialog"
      v-model="showEmailChangeConfirmDialog"
      width="800"
      persistent
      no-click-animation
    >
      <email-change-confirm
        :code-id="emailChangeCodeId"
        @close="showEmailChangeConfirmDialog = false"
        @emailChanged="closeEmailChangeDialogs"
      ></email-change-confirm>
    </v-dialog>

    <!-- パスワード変更 -->
    <v-dialog
      v-if="showPasswordChangeDialog"
      v-model="showPasswordChangeDialog"
      width="800"
      persistent
      no-click-animation
    >
      <password-change @close="showPasswordChangeDialog = false"></password-change>
    </v-dialog>
  </v-container>
</template>

<script>
import AlertSetting from "@/components/dialogs/AlertSetting";
import EmailChange from "@/components/dialogs/EmailChange.vue";
import EmailChangeConfirm from "@/components/dialogs/EmailChangeConfirm.vue";
import PasswordChange from "@/components/dialogs/PasswordChange";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "Setting",
  components: { AlertSettingDialog: AlertSetting, EmailChange, EmailChangeConfirm, PasswordChange },
  data: () => ({
    menus: ["心不全予兆アラート設定", "メールアドレス", "パスワード"],
    // 心不全予兆
    alertSettingDialog: {
      params: {
        displayMode: DIALOG_DISPLAY_MODE.DETAIL,
      },
      show: false,
    },
    // メールアドレス変更
    showEmailChangeDialog: false,
    // メールアドレス変更コード確認
    showEmailChangeConfirmDialog: false,
    // メールアドレス変更コードID
    emailChangeCodeId: "",
    // パスワード変更
    showPasswordChangeDialog: false,
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    action(index) {
      switch (this.menus[index]) {
        case "ログアウト":
          signOut(getAuth())
            .then(() => {
              this.$router.push("/").catch(() => {});
            })
            .catch(() => {});
          break;

        case "メールアドレス":
          this.showEmailChangeDialog = true;
          break;

        case "心不全予兆アラート設定":
          this.alertSettingDialog.show = true;
          break;

        case "パスワード":
          this.showPasswordChangeDialog = true;
          break;
      }
    },
    openEmailChangeCodeConfirmDialog(codeId) {
      this.emailChangeCodeId = codeId;
      this.showEmailChangeConfirmDialog = true;
    },
    closeEmailChangeDialogs() {
      this.emailChangeCodeId = "";
      this.showEmailChangeConfirmDialog = false;
      this.showEmailChangeDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
