<template>
  <Dialog @close="$emit('close')">
    <!-- タイトル -->
    <template #title>
      <span class="text-base">薬設定</span>
    </template>

    <!-- コンテンツ -->
    <template #contents>
      <div class="px-6 space-y-4">
        <!-- 薬 -->
        <v-data-table
          :headers="headers"
          :items="localMedications"
          :height="tableHeight"
          :items-per-page="-1"
          fixed-header
          dense
          hide-default-footer
          class="row-height"
        >
          <template #no-data>
            <div class="text-center text-gray-500 py-4">薬が登録されていません</div>
          </template>

          <template #body="{ items }">
            <draggable tag="tbody" handle=".handle" :list="localMedications">
              <tr v-for="(medication, index) in items" :key="index">
                <!-- 病名 -->
                <td>
                  <v-text-field
                    v-model="localMedications[index].name"
                    hide-details
                    outlined
                    dense
                    clearable
                    clear-icon="mdi-close-circle"
                  />
                </td>

                <!-- アクション -->
                <td>
                  <div class="flex justify-center items-center space-x-4">
                    <v-btn
                      color="primary"
                      small
                      :disabled="localMedications.length >= LIMIT_ROWS"
                      @click="addMedication(index)"
                    >
                      追加
                    </v-btn>
                    <v-btn
                      small
                      :disabled="localMedications.length <= 1"
                      @click="removeMedication(index)"
                    >
                      削除
                    </v-btn>
                    <div class="handle cursor-pointer">
                      <v-icon color="tertiary">mdi-dots-grid</v-icon>
                    </div>
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>

        <!-- ボタンエリア -->
        <div class="flex justify-end space-x-4">
          <v-btn elevation="0" @click="$emit('close')"> キャンセル </v-btn>
          <v-btn elevation="0" color="primary" @click="setMedication()"> 設定 </v-btn>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/templates/Dialog";
import draggable from "vuedraggable";

export default {
  name: "Medication",
  components: {
    Dialog,
    draggable,
  },
  props: {
    medications: {
      type: Array,
      default: () => [{ name: "" }],
    },
  },
  data() {
    return {
      // テーブル設定
      LIMIT_ROWS: 10, // （追加可能な）最大行数
      LIMIT_VISIBLE_ROWS: 10, // 表示領域の最大行数
      HEADER_HEIGHT: 32, // ヘッダーの高さ (px)
      ROW_HEIGHT: 48, // 1行の高さ (px)

      headers: [
        { text: "薬名", value: "name", sortable: false },
        { text: "", value: "edit", width: "156px", sortable: false },
      ],

      localMedications:
        this.medications && this.medications.length > 0
          ? JSON.parse(JSON.stringify(this.medications))
          : [{ name: "" }],
    };
  },
  computed: {
    tableHeight() {
      return (
        this.HEADER_HEIGHT + this.ROW_HEIGHT * Math.min(this.LIMIT_ROWS, this.LIMIT_VISIBLE_ROWS)
      );
    },
  },
  methods: {
    addMedication(index) {
      this.localMedications.splice(index + 1, 0, { name: "" });
    },
    removeMedication(index) {
      this.localMedications.splice(index, 1);
    },
    setMedication() {
      this.localMedications = this.localMedications.filter(
        (medication) => medication.name && medication.name.trim() != ""
      );
      this.$emit("set", this.localMedications);
    },
  },
};
</script>

<style scoped>
.v-data-table.row-height td {
  height: 48px !important;
}
</style>
