<template>
  <v-app>
    <!-- ヘッダー -->
    <v-app-bar v-if="!isErrorPage" app color="primary" dark dense elevation="0" height="56px">
      <v-row class="items-center">
        <v-col class="text-left">
          <!-- 戻るボタン（認証前の画面で利用） -->
          <v-icon v-show="showBackButton" @click="goBack"> mdi-chevron-left </v-icon>
          <!-- 画面タイトル -->
          {{ title }}
        </v-col>
        <!-- ログインユーザー -->
        <v-col class="text-right">
          <span class="mr-6">{{ selfUser?.lastName }} {{ selfUser?.firstName }}</span>
          <span class="text-sm text-gray-300">Ver. {{ version }}</span></v-col
        >
      </v-row>
    </v-app-bar>

    <!-- サイドメニュー -->
    <side-menu v-if="loggedIn && !isErrorPage" v-model="openedSideMenu" />

    <!-- メイン -->
    <v-main>
      <router-view />
    </v-main>

    <!-- ローディング -->
    <v-overlay
      v-show="$store.state.api.runningApiCount > 0"
      class="loading-overlay"
      absolute
      opacity="0.2"
    >
      <v-progress-circular color="primary" size="120" width="10" indeterminate />
    </v-overlay>

    <!-- スナックバー -->
    <v-snackbar
      v-model="$store.state.snackbar.snackbar"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
      min-width="500px"
    >
      <span class="text-xl whitespace-pre">{{ $store.state.snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon small @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- フッター -->
    <v-footer app padless class="block">
      <div class="my-1">
        <ul class="flex flex-col sm:flex-row sm:justify-center sm:space-x-6 space-y-2 sm:space-y-0">
          <li>
            <a @click="termsDialog.show = true">利用規約</a>
          </li>
          <li>
            <a @click="privacyDialog.show = true">プライバシーポリシー</a>
          </li>
          <li>
            <a @click="goToContact">お問い合わせ</a>
          </li>
        </ul>
      </div>
      <v-row no-gutters class="mb-1">
        <v-col class="text-center">
          <span class="text-sm text-gray-500"
            >© Department of Cardiovascular Medicine and Hypertension, KAGOSHIMA UNIVERSITY Graduate
            School of Medical and Dental Sciences.</span
          >
        </v-col>
      </v-row>
    </v-footer>

    <!-- 利用規約ダイアログ -->
    <v-dialog v-model="termsDialog.show" max-width="720">
      <terms-dialog
        v-if="termsDialog.show"
        title="利用規約及びプライバシーポリシー"
        @close="termsDialog.show = false"
      ></terms-dialog>
    </v-dialog>

    <!-- プライバシーポリシーダイアログ -->
    <v-dialog v-model="privacyDialog.show" max-width="720">
      <privacy-dialog
        v-if="privacyDialog.show"
        title="利用規約及びプライバシーポリシー"
        @close="privacyDialog.show = false"
      ></privacy-dialog>
    </v-dialog>

    <!-- 外部サイト移動確認ダイアログ -->
    <v-dialog v-model="externalSiteConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="externalSiteConfirmDialog.show"
        title="外部サイトへ移動します。よろしいですか？"
        @ok="toExternalSite"
        @cancel="externalSiteConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>
  </v-app>
</template>

<script>
import memberMixin from "@//mixins/memberMixin";
import Confirm from "@/components/dialogs/Confirm";
import Privacy from "@/components/dialogs/Privacy";
import Terms from "@/components/dialogs/Terms";
import SideMenu from "@/components/organisms/SideMenu.vue";
import { MESSAGES } from "@/const/message";
import doctorMixin from "@/mixins/doctorMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import fcmTokenMixin from "@/mixins/fcmTokenMixin";
import hospitalMixin from "@/mixins/hospitalMixin";
import patientMixin from "@/mixins/patientMixin";
import userMixin from "@/mixins/userMixin";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  mixins: [
    userMixin(),
    hospitalMixin(),
    doctorMixin(),
    patientMixin(),
    memberMixin(),
    fcmTokenMixin(),
    errorHandlerMixin(),
  ],
  components: { SideMenu, ConfirmDialog: Confirm, TermsDialog: Terms, PrivacyDialog: Privacy },
  data: () => ({
    version: require("../package.json").version,

    // UID
    uid: null,

    // サイドメニュー幅を縮小
    openedSideMenu: false,

    // 利用規約ダイアログ
    termsDialog: {
      show: false,
    },

    // プライバシーポリシーダイアログ
    privacyDialog: {
      show: false,
    },

    // 外部サイト移動確認ダイアログ
    externalSiteConfirmDialog: {
      targetUrl: "",
      show: false,
    },
  }),
  computed: {
    ...mapState("user", ["selfUser"]),
    // パス
    path() {
      return this.$route.path;
    },

    // タイトル
    title() {
      if (this.$route.path.split("/")[1] == "") {
        return "管理システム";
      } else {
        return this.$route.meta.title;
      }
    },

    // 戻るボタンを表示するか
    showBackButton() {
      if (this.$route.path.split("/").length > 2) {
        return true;
      }

      switch (this.$route.path.split("/")[1]) {
        case "password-resetting-confirm":
          return true;
        default:
          return false;
      }
    },

    // ログイン済みか
    loggedIn() {
      switch (this.$route.path.split("/")[1]) {
        case "":
        case "password-resetting":
        case "password-resetting-confirm":
        case "password-resetting-complete":
        case "new-password-setting":
          return false;

        default:
          return true;
      }
    },

    // エラーページであるかどうか
    isErrorPage() {
      return this.$route.meta.isErrorPage;
    },
  },
  created() {
    // ユーザーの認証ステータスを購読
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      // ログイン時や、既にログインしている場合
      if (user) {
        // UID を設定
        this.uid = user.uid;

        // 自身のユーザー（関連するメンバー・患者情報を含む）情報を取得
        await this.getSelfUserAndRelatedUser(this.uid);

        // 自身のユーザー情報を購読
        this.addUnsubscribe({ unsubscribe: this.subscribeSelfUserAndRelatedUser(this.uid) });

        // 病院一覧を購読
        this.addUnsubscribe({ unsubscribe: this.subscribeHospitals() });

        // 医師一覧を購読
        this.addUnsubscribe({ unsubscribe: this.subscribeDoctors() });

        // 患者一覧を購読
        this.addUnsubscribe({ unsubscribe: this.subscribePatients() });

        // メンバー一覧を購読
        this.addUnsubscribe({ unsubscribe: this.subscribeMembers() });

        // FCM トークンを更新
        this.updateFcmToken(this.uid);

        // アプリの表示状態が変わった際に呼び出されるイベントリスナーを追加
        document.addEventListener("visibilitychange", this.onVisibilityChange);
      } else {
        // 購読を解除
        this.unsubscribes();
        // stateを初期化
        this.resetState();

        // アプリの表示状態が変わった際に呼び出されるイベントリスナーを削除
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
      }
    });
  },
  mounted() {
    // v-dialog を draggable にする
    this.addDraggableDialogEvent();
  },

  methods: {
    ...mapActions(["resetState"]),
    ...mapActions("snackbar", ["closeSnackbar"]),
    ...mapActions("unsubscribe", ["addUnsubscribe"]),

    // 戻る
    goBack() {
      switch (this.$route.path.split("/")[1]) {
        case "password-resetting":
          this.$router.push("/").catch(() => {
            this.showError(MESSAGES.ERRORS.UNEXPECTED);
          });
          break;

        case "password-resetting-confirm":
          this.$router.push("/password-resetting").catch(() => {});
          break;
      }
    },

    // v-dialog を draggable にする
    addDraggableDialogEvent() {
      // See. https://github.com/vuetifyjs/vuetify/issues/4058
      const d = {};
      document.addEventListener("mousedown", (e) => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (
          e.button === 0 &&
          closestDialog != null &&
          e.target.classList.contains("v-card__title")
        ) {
          d.el = closestDialog;
          d.mouseStartX = e.clientX;
          d.mouseStartY = e.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      });

      document.addEventListener("mousemove", (e) => {
        if (d.el === undefined) return;
        d.el.style.left =
          Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
          ) + "px";
        d.el.style.top =
          Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
          ) + "px";
      });

      document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      });

      setInterval(() => {
        // prevent out of bounds
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left =
          Math.min(
            parseInt(dialog.style.left),
            window.innerWidth - dialog.getBoundingClientRect().width
          ) + "px";
        dialog.style.top =
          Math.min(
            parseInt(dialog.style.top),
            window.innerHeight - dialog.getBoundingClientRect().height
          ) + "px";
      }, 100);
    },
    // アプリの表示状態が変わった際に呼び出されるメソッド
    async onVisibilityChange() {
      if (document.visibilityState === "visible") {
        // // FCM トークンの更新
        await this.updateFcmToken(this.uid, true);
      }
    },
    // 各種購読を解除
    unsubscribes() {
      // storeに格納された購読解除を実行
      this.$store.state.unsubscribe.unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
      });
    },
    // 外部サイト移動の確認ダイアログを表示
    confirmExternalSite(targetUrl) {
      this.externalSiteConfirmDialog.targetUrl = targetUrl;
      this.externalSiteConfirmDialog.show = true;
    },
    // 外部サイトへ移動
    toExternalSite() {
      const targetUrl = this.externalSiteConfirmDialog.targetUrl;
      this.externalSiteConfirmDialog.show = false;
      if (targetUrl) {
        window.open(targetUrl, "_blank");
      }
    },
    // お問い合わせページへ遷移
    goToContact() {
      // バージョン
      let queryString = `${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_VERSION}=${this.version}`;
      if (this.loggedIn) {
        const { lastName, firstName, lastNameKana, firstNameKana, email } =
          this.$store.state.user.selfUser;

        // 氏名
        queryString += `&${
          process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_NAME
        }=${encodeURIComponent(`${lastName} ${firstName}`)}`;
        // 氏名かな
        queryString += `&${
          process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_NAME_KANA
        }=${encodeURIComponent(`${lastNameKana} ${firstNameKana}`)}`;
        // メールアドレス
        queryString += `&${
          process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_EMAIL
        }=${encodeURIComponent(email)}`;
      }

      this.confirmExternalSite(`${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_URL}?${queryString}`);
    },
  },
};
</script>

<style lang="scss">
.v-size--x-large {
  min-width: 0 !important;
}

.loading-overlay {
  z-index: 999 !important;
}
</style>
