<template>
  <div v-resize="setContentHeight">
    <v-data-table v-if="displayMode == DIALOG_DISPLAY_MODE.DETAIL" class="cursor-pointer" :headers="headers"
      :items="showSelectedDoctors" :items-per-page="-1" sort-by="nameKana" hide-default-footer @click:row="(item) => {
        showDoctorDialog(item);
      }
        ">
    </v-data-table>

    <div v-if="displayMode == DIALOG_DISPLAY_MODE.UPDATE" class="pb-4">
      <v-data-table v-model="selectedDoctors" class="cursor-pointer" :headers="headers"
        :items="filteredDoctorCandidates" :height="contentHeight" :items-per-page="-1" hide-default-footer show-select
        item-key="uid" sort-by="nameKana" @click:row="(item) => {
          showDoctorDialog(item);
        }
          ">
      </v-data-table>
    </div>

    <!-- 医師詳細ダイアログ -->
    <v-dialog v-if="doctorDialog.show" v-model="doctorDialog.show" width="800" persistent no-click-animation>
      <doctor-dialog v-bind="doctorDialog.params" @close="doctorDialog.show = false"></doctor-dialog>
    </v-dialog>
  </div>
</template>

<script>
import Doctor from "@/components/dialogs/Doctor";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import patientMixin from "@/mixins/patientMixin";
import { mapState } from "vuex";

export default {
  name: "ResponsibleDoctorList",
  mixins: [patientMixin()],
  components: { DoctorDialog: Doctor },
  props: {
    // ダイアログ表示モード
    displayMode: {
      required: true,
    },
    // 患者UID
    patientUid: {
      required: true,
    },
    // 検索する病院
    searchHospitalIds: {
      required: false,
      default: () => [],
    },
    // 検索キーワード
    searchKeyword: {
      required: false,
      default: "",
    },
  },
  data: () => ({
    // コンテンツの高さ
    contentHeight: 0,
    // ヘッダー
    headers: [
      { text: "病院名", value: "hospitalNames" },
      { text: "氏名", value: "name" },
      { text: "氏名（かな）", value: "nameKana" },
    ],
    // 選択されている医師
    selectedDoctors: [],
    // 表示モード
    DIALOG_DISPLAY_MODE,
    // 医師詳細ダイアログ
    doctorDialog: {
      show: false,
      params: {
        member: null,
      },
    },
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 医師情報
    ...mapState("doctor", ["doctors"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    patient() {
      return this.getPatient(this.patientUid);
    },
    // 複数病院に所属しているかどうか
    hasMultiHospitals() {
      return this.selfUser?.hospitalIds.length > 1;
    },
    // フィルター済みの医師情報
    filteredDoctorCandidates() {
      let filteredDoctorCandidates = this.doctors.map((doctor) => {
        // 医師の病院IDに紐づく病院名の取得
        const hospitalNames = doctor.hospitalIds.map((hospitalId) => {
          const hospital = this.hospitals.find((hospital) => hospital.id == hospitalId);
          return hospital?.name;
        });
        return {
          ...doctor,
          hospitalNames,
          name: `${doctor.lastName} ${doctor.firstName}`,
          nameKana: `${doctor.lastNameKana} ${doctor.firstNameKana}`,
        };
      });

      // 病院でフィルター
      if (this.searchHospitalIds.length > 0) {
        filteredDoctorCandidates = filteredDoctorCandidates.filter((doctorCandidate) =>
          doctorCandidate.hospitalIds.some((hospitalId) =>
            this.searchHospitalIds.includes(hospitalId)
          )
        );
      }

      // キーワードでフィルター（半角スペース区切りのキーワードで、氏名・かなを AND 検索
      if (this.searchKeyword) {
        const searchKeywords = this.searchKeyword.replace(/[\x20\u3000]/g, " ").split(" ");
        searchKeywords.forEach((searchKeyword) => {
          filteredDoctorCandidates = filteredDoctorCandidates.filter((doctorCandidate) => {
            const { name, nameKana } = doctorCandidate;
            return name.includes(searchKeyword) || nameKana.includes(searchKeyword);
          });
        });
      }
      this.$emit("update:filteredDoctorLength", filteredDoctorCandidates.length);
      return filteredDoctorCandidates;
    },
    showSelectedDoctors() {
      this.initDoctorCandidateList();
      return this.selectedDoctors;
    },
  },
  created() {
    // 担当医一覧を初期化
    this.initDoctorCandidateList();

    if (!this.hasMultiHospitals) {
      // 病院名列を除く
      this.headers = this.headers.filter(header => header.value !== "hospitalNames");
    }
  },
  methods: {
    // コンテンツの高さを設定
    setContentHeight() {
      this.contentHeight = window.innerHeight - 380;
    },
    // 担当医一覧を初期化
    initDoctorCandidateList() {
      // 担当医を初期化
      this.selectedDoctors = [];
      if (this.patient.doctorUids) {
        this.filteredDoctorCandidates.forEach((filteredDoctorCandidate) => {
          if (this.patient.doctorUids.includes(filteredDoctorCandidate.uid)) {
            this.selectedDoctors.push(filteredDoctorCandidate);
          }
        });
      }
    },
    // 医師詳細ダイアログを表示
    showDoctorDialog(doctor) {
      this.doctorDialog.params = {
        doctor,
        displayMode: DIALOG_DISPLAY_MODE.DETAIL,
      };
      this.doctorDialog.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
// 医師一覧
.v-data-table {
  @apply border;
}

// 一覧の行
::v-deep tbody>tr:last-child>td {
  @apply border-b;
  @apply truncate;
}
</style>
