<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Dialog @close="$emit('close')">
      <!-- タイトル -->
      <template #[`title`]>
        {{ title }}
      </template>

      <!-- ヘッダーボタン -->
      <template #[`header-buttons`]>
        <!-- 編集ボタン -->
        <v-btn v-if="readonly" icon large @click="showUpdateDialog">
          <v-icon large color="primary">mdi-pencil</v-icon>
        </v-btn>
      </template>

      <!-- コンテンツ -->
      <template #[`contents`]>
        <!-- 病院名 -->
        <div v-if="hasMultiHospitals" class="flex items-center">
          <div class="w-64 pb-6">病院名<span v-if="!readonly" class="ml-1 red--text">*</span></div>
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="病院名" rules="required">
              <v-text-field v-if="readonly" :value="hospitalNames" dense outlined disabled>
              </v-text-field>
              <v-select
                v-else
                v-model="localMember.hospitalIds"
                :items="hospitals"
                item-value="id"
                item-text="name"
                placeholder="病院名"
                multiple
                dense
                outlined
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </div>
        </div>

        <!-- 種別（ラベル） -->
        <div class="flex items-center">
          <div class="w-64 pb-6">種別<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <!-- 種別選択 -->
          <div class="w-60 pr-4">
            <validation-provider v-slot="{ errors }" name="種別" rules="required">
              <v-text-field
                v-if="readonly"
                :value="LABEL_TYPES[localMember.labelType].label"
                dense
                outlined
                disabled
              >
              </v-text-field>
              <v-select
                v-else
                v-model="localMember.labelType"
                :items="filteredLabelTypes"
                item-value="value"
                item-text="label"
                outlined
                dense
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-select>
            </validation-provider>
          </div>

          <!-- 種別その他 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="種別その他">
              <v-text-field
                v-model="localMember.labelTypeOther"
                dense
                placeholder="種別を入力"
                outlined
                :disabled="readonly"
                :error-messages="errors"
                v-show="localMember.labelType == 'other'"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名 -->
        <div class="flex items-center">
          <div class="w-64 pb-6">氏名<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <!-- 姓 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="姓" rules="required|max:10">
              <v-text-field
                v-model="localMember.lastName"
                class="mr-4"
                placeholder="姓"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 名 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="名" rules="required|max:10">
              <v-text-field
                v-model="localMember.firstName"
                placeholder="名"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名（かな） -->
        <div class="flex items-center">
          <div class="w-64 pb-6">
            氏名（かな）<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>

          <!-- 姓（かな） -->
          <div class="w-60">
            <validation-provider v-slot="{ errors }" name="せい" rules="required|hiragana|max:20">
              <v-text-field
                v-model="localMember.lastNameKana"
                class="mr-4"
                placeholder="せい"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 名（かな） -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="めい" rules="required|hiragana|max:20">
              <v-text-field
                v-model="localMember.firstNameKana"
                placeholder="めい"
                dense
                outlined
                :disabled="readonly"
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 性別 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">性別<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <validation-provider v-slot="{ errors }" name="性別">
            <v-text-field
              v-if="readonly"
              :value="GENDER_LABEL[localMember.gender]"
              dense
              outlined
              disabled
            >
            </v-text-field>
            <v-radio-group
              v-else
              class="mt-0"
              v-model="localMember.gender"
              row
              :error-messages="errors"
            >
              <v-radio
                v-for="gender in GENDERS"
                :key="gender.value"
                :label="gender.label"
                :value="gender.value"
              >
              </v-radio>
            </v-radio-group>
          </validation-provider>
        </div>

        <!-- 生年月日 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">
            生年月日<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>
          <DateOfBirthPicker v-model="selectedDate" :readonly="readonly" />
        </div>

        <!-- 住所 -->
        <div class="h-16 pb-6 flex items-center">住所</div>

        <!-- 住所詳細 -->
        <div class="pl-8">
          <!-- 郵便番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              {{ readonly ? "郵便番号" : "郵便番号（ハイフンなし）" }}
              <span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="w-24">
              <validation-provider v-slot="{ errors }" name="郵便番号" rules="required|digits:7">
                <v-text-field
                  v-if="readonly"
                  v-model="displayPostCode"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="localMember.postCode"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 都道府県 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              都道府県<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="w-32">
              <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
                <v-text-field
                  v-if="readonly"
                  :value="PREFECTURE_LABEL[localMember.prefectureCode]"
                  dense
                  outlined
                  disabled
                >
                </v-text-field>
                <v-select
                  v-else
                  v-model="localMember.prefectureCode"
                  :items="PREFECTURES"
                  item-value="value"
                  item-text="label"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-select>
              </validation-provider>
            </div>
          </div>

          <!-- 市区町村 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              市区町村<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="市区町村" rules="required|max:30">
                <v-text-field
                  v-model="localMember.municipality"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 番地 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">番地<span v-if="!readonly" class="ml-1 red--text">*</span></div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="番地" rules="required|max:20">
                <v-text-field
                  v-model="localMember.addressNumber"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 建物名・部屋番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">建物名・部屋番号</div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="建物名・部屋番号" rules="max:50">
                <v-text-field
                  v-model="localMember.buildingNameAndRoomNumber"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>

        <!-- 連絡先 -->
        <div class="h-16 pb-6 flex items-center">連絡先</div>

        <!-- 連絡先詳細 -->
        <div class="pl-8">
          <!-- メールアドレス -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              メールアドレス<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="メールアドレス" rules="required|email">
                <v-text-field
                  v-model="localMember.email"
                  type="email"
                  outlined
                  dense
                  :disabled="localDisplayMode != DIALOG_DISPLAY_MODE.REGISTER"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 電話番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              {{ readonly ? "電話番号" : "電話番号（ハイフンなし）" }}
              <span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>

            <div class="flex-auto">
              <validation-provider
                v-slot="{ errors }"
                name="電話番号"
                rules="required|numeric|max:11"
              >
                <v-text-field
                  v-if="readonly"
                  v-model="localMember.phoneNumber"
                  type="tel"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="localMember.phoneNumber"
                  type="tel"
                  outlined
                  dense
                  :disabled="readonly"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>

        <div v-if="localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER">
          <!-- パスワード -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード<span v-if="!readonly" class="ml-1 red--text">*</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <div>
                  パスワードは以下を満たす必要があります
                  <ul class="list-disc">
                    <li>{{ PASSWORD_MIN_LENGTH }}文字以上</li>
                    <li>英字・数字・記号を含む</li>
                    <li>使用可能な記号{{ PASSWORD_ENABLE_SYMBOLS }}</li>
                  </ul>
                </div>
              </v-tooltip>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="パスワード" rules="required|password">
                <v-text-field
                  v-model="localMember.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  dense
                  autoComplete="new-password"
                  solo
                  flat
                  outlined
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- パスワード（確認用） -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード（確認用）<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider
                v-slot="{ errors }"
                name="パスワード（確認用）"
                :rules="`required|password|password_confirm:${localMember.password}`"
              >
                <v-text-field
                  v-model="localMember.passwordConfirm"
                  :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordConfirm ? 'text' : 'password'"
                  dense
                  outlined
                  :error-messages="errors"
                  @click:append="showPasswordConfirm = !showPasswordConfirm"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>
      </template>

      <!-- フッターボタン -->
      <template #[`footer-buttons`]>
        <div v-if="localDisplayMode == DIALOG_DISPLAY_MODE.UPDATE">
          <v-btn elevation="0" @click="changeDisplayModeDetail">
            <div>キャンセル</div>
          </v-btn>
        </div>
        <div v-else>
          <v-btn elevation="0" @click="$emit('close')">
            <div>
              {{ localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL ? "閉じる" : "キャンセル" }}
            </div>
          </v-btn>
        </div>

        <!-- 登録ボタン -->
        <v-btn
          v-if="localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="registerMember"
        >
          登録
        </v-btn>

        <!-- 更新ボタン -->
        <v-btn
          v-if="localDisplayMode == DIALOG_DISPLAY_MODE.UPDATE"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="updateMember"
        >
          更新
        </v-btn>
      </template>
    </Dialog>
  </validation-observer>
</template>

<script>
import DateOfBirthPicker from "@/components/molecules/DateOfBirthPicker";
import Dialog from "@/components/templates/Dialog";
import {
  DIALOG_DISPLAY_MODE,
  GENDER,
  GENDER_LABEL,
  GENDERS,
  LABEL_TYPES,
  PASSWORD_ENABLE_SYMBOLS,
  PASSWORD_MIN_LENGTH,
  PREFECTURE_LABEL,
  PREFECTURES,
  TYPE_TO_LABEL_TYPES,
} from "@/const/const";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { doc, serverTimestamp, Timestamp, updateDoc } from "firebase/firestore";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  name: "MemberDialog",
  components: {
    DateOfBirthPicker,
    Dialog,
  },
  mixins: [errorHandlerMixin()],
  props: {
    // ダイアログ表示モード
    displayMode: {
      required: true,
    },
    // メンバー項目
    member: {
      default: null,
    },
  },
  data: () => ({
    PASSWORD_MIN_LENGTH,
    PASSWORD_ENABLE_SYMBOLS,
    // 表示モード
    DIALOG_DISPLAY_MODE,
    // ダイアログ表示モード
    localDisplayMode: null,

    // ラベル種別
    LABEL_TYPES,
    // ユーザー種別に対応するラベル種別
    TYPE_TO_LABEL_TYPES,

    // 性別
    GENDERS,
    // 性別のラベル
    GENDER_LABEL,

    // 都道府県のラベル
    PREFECTURE_LABEL,
    // カレンダー表示のv-menu用
    showCalendar: false,
    // 選択された日付
    selectedDate: "",

    // パスワード表示制御
    showPassword: false,
    showPasswordConfirm: false,

    // 都道府県情報
    PREFECTURES,
    // メンバー情報
    localMember: {
      // UID
      uid: "",
      // 病院
      hospitalIds: [],
      // 種別
      labelType: "",
      // 種別その他
      labelTypeOther: "",
      // 姓
      lastName: "",
      // 名
      firstName: "",
      // 姓(かな)
      lastNameKana: "",
      // 名(かな)
      firstNameKana: "",
      // 性別
      gender: GENDER.UNSELECTED,
      // 生年月日
      dateOfBirth: null,
      // 郵便番号
      postCode: "",
      // 都道府県コード
      prefectureCode: "",
      // 市区町村
      municipality: "",
      // 番地
      addressNumber: "",
      // 建物名・部屋番号
      buildingNameAndRoomNumber: "",
      // メールアドレス
      email: "",
      // 電話番号
      phoneNumber: "",
      // パスワード
      password: "",
    },
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // ログインユーザー
    ...mapState("user", ["selfUser"]),

    // 病院情報
    ...mapState("hospital", ["hospitals"]),

    // タイトル
    title() {
      let suffix = "";
      switch (this.localDisplayMode) {
        case DIALOG_DISPLAY_MODE.REGISTER:
          suffix = "登録";
          break;
        case DIALOG_DISPLAY_MODE.UPDATE:
          suffix = "編集";
          break;
        case DIALOG_DISPLAY_MODE.DETAIL:
          suffix = "詳細";
          break;
      }
      return `メンバー${suffix}`;
    },

    // 複数病院に所属しているかどうか
    hasMultiHospitals() {
      return this.selfUser?.hospitalIds.length > 1;
    },

    // 病院名表示
    hospitalNames() {
      return this.hospitals
        .filter((hospital) => this.member.hospitalIds.includes(hospital.id))
        .map((hospital) => hospital.name)
        .join(", ");
    },

    // 読み取り専用か
    readonly() {
      return this.localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL;
    },

    // 表示用の郵便番号
    displayPostCode() {
      const { postCode } = this.member;
      return `${postCode.substr(0, 3)}-${postCode.substr(3)}`;
    },

    // ラベル種別選択肢
    filteredLabelTypes() {
      return TYPE_TO_LABEL_TYPES["member"].map((labelType) => ({
        value: labelType,
        label: LABEL_TYPES[labelType].label,
      }));
    },
  },
  created() {
    this.localDisplayMode = this.displayMode;
    // 登録
    if (this.localDisplayMode == DIALOG_DISPLAY_MODE.REGISTER) {
      if (!this.hasMultiHospitals) {
        this.localMember.hospitalIds.push(this.selfUser.hospitalIds[0]);
      }
    }
    // 詳細
    if (this.localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL) {
      this.localMember = { ...this.member };
    }

    // v-selectに初期表示で都道府県を表示するためNumberに変換
    this.localMember.prefectureCode = Number(this.localMember.prefectureCode);

    if (this.displayMode != DIALOG_DISPLAY_MODE.REGISTER) {
      // timestamp型をカレンダーに対応する形式に変換
      this.selectedDate = moment(this.localMember.dateOfBirth.toDate()).format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // 表示を編集モードに切り替える
    showUpdateDialog() {
      this.localDisplayMode = DIALOG_DISPLAY_MODE.UPDATE;
    },
    // 表示を詳細モードに切り替える
    changeDisplayModeDetail() {
      this.localMember = { ...this.member };
      this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;
    },

    // 登録
    async registerMember() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        this.localMember.dateOfBirth = this.selectedDate;

        // メンバーを追加
        const registerMemberFunction = this.$httpsCallable(this.$functions, "registermember");
        await registerMemberFunction(this.localMember);

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("complete");
      } catch (error) {
        if (error.message === "auth/already-exists") {
          this.showError("メールアドレスが登録済みです");
        } else if (error.message === "auth/invalid-password") {
          this.showError("パスワードの形式が不正です");
        } else {
          this.showError("メンバー情報の登録に失敗しました");
        }
      } finally {
        this.isProcessing = false;
      }
    },
    // 編集
    async updateMember() {
      // 種別でその他以外が選択されている場合、種別その他をリセット
      if (this.localMember.labelType != "other") {
        this.localMember.labelTypeOther = "";
      }

      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        const {
          uid,
          hospitalIds,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          gender,
          postCode,
          prefectureCode,
          municipality,
          addressNumber,
          buildingNameAndRoomNumber,
          phoneNumber,
          labelType,
          labelTypeOther,
        } = this.localMember;

        let { dateOfBirth } = this.localMember;

        // 選択された日付をTimestamp型に変換
        const birthDay = new Date(`${this.selectedDate}T00:00:00Z`);
        dateOfBirth = Timestamp.fromDate(birthDay);

        const memberDoc = await doc(this.$firestore, "users", uid);

        // 患者情報を更新
        await updateDoc(memberDoc, {
          uid,
          hospitalIds,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          gender,
          dateOfBirth,
          postCode,
          prefectureCode,
          municipality,
          addressNumber,
          buildingNameAndRoomNumber,
          phoneNumber,
          labelType,
          labelTypeOther,
          updated: serverTimestamp(),
          updatedUserId: this.selfUser.uid,
        });

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("update");
      } catch (error) {
        this.showError("メンバー情報の更新に失敗しました");
      } finally {
        this.isProcessing = false;
      }
    },
    // 成功
    showSuccess() {
      const action = this.displayMode == DIALOG_DISPLAY_MODE.REGISTER ? "登録" : "更新";
      this.$store.dispatch("snackbar/openSnackbar", {
        text: `メンバー情報（${this.localMember.lastName} ${this.localMember.firstName}）を${action}しました`,
        color: "success",
      });
    },
  },
};
</script>
