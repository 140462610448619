import { collection, onSnapshot } from "firebase/firestore";
import { mapActions, mapState } from "vuex";

export default function () {
  return {
    computed: {
      ...mapState("user", ["selfUser"]),
    },
    methods: {
      ...mapActions("hospital", ["addHospital", "deleteHospital"]),

      // 全病院情報を購読
      subscribeHospitals() {
        // 病院情報を取得
        const db = this.$firestore;

        try {
          return onSnapshot(
            collection(db, "hospitals"),
            (snapshot) => {
              snapshot.docChanges().forEach((change) => {
                const doc = change.doc;

                switch (change.type) {
                  // 病院追加,病院更新
                  case "added":
                  case "modified":
                    this.addHospital({ id: doc.id, ...doc.data() });
                    break;
                  // 病院削除
                  case "removed":
                    this.deleteHospital({ id: doc.id });
                    break;
                }
              });
            },
            () => {
              location.reload();
            }
          );
        } catch (error) {
          // TODO: エラーハンドリング
          console.log(error);
        }
      },
    },
  };
}
