<template>
  <div class="flex items-center justify-center" style="height: 250px">
    <!-- グラフ描写までの間読込中 -->
    <v-progress-circular
      v-if="loading"
      color="primary"
      size="40"
      indeterminate
    ></v-progress-circular>

    <!-- グラフ -->
    <template v-else>
      <apexchart
        v-if="hasGraphData"
        class="w-full"
        type="line"
        height="250px"
        :options="chart.options"
        :series="chart.series"
      ></apexchart>
      <div v-else>
        <div>データがありません</div>
      </div>
    </template>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "BloodPressureGraph",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    // 日付
    date: {
      required: true,
      type: String,
    },
    // 患者uid
    patientUid: {
      required: true,
    },
  },
  data: () => ({
    // 読込中か
    loading: null,

    // 折れ線グラフ
    chart: {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: true,
          hideEmptySeries: true,
          custom({ series, seriesIndex, dataPointIndex }) {
            if (series[seriesIndex][dataPointIndex] == null) {
              return null;
            }

            return `<div class="py-2 px-4">
              <div class="pl-1">最高: <span class="text-amber-500 font-bold">${
                series[1][dataPointIndex] != null ? series[1][dataPointIndex] : "-"
              }</span> mmHg</div>
              <div class="pl-1">最低: <span class="text-blue-500 font-bold">${
                series[0][dataPointIndex] != null ? series[0][dataPointIndex] : "-"
              }</span> mmHg</div>
              </div>`;
          },
        },
        grid: {
          padding: {
            right: 30,
          },
        },
        stroke: {
          width: 3,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          stepSize: 5,
        },
        xaxis: {
          labels: {
            formatter(value) {
              const date = moment(value);
              return date.hour() == 0 ? date.format("D") : "";
            },
          },
          tooltip: {
            enabled: true,
            formatter(value) {
              return value % 2 == 0 ? "夜" : "朝";
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "",
          data: [],
          color: "#3B82F6", // text-blue-500
        },
        {
          name: "",
          data: [],
          color: "#F59E0B", // text-amber-500
        },
      ],
    },
  }),
  computed: {
    // グラフデータの有無
    hasGraphData() {
      return !(
        this.chart.series[0].data.every((data) => data.y == null) ||
        this.chart.series[1].data.every((data) => data.y == null)
      );
    },
  },
  watch: {
    date() {
      // 日付変更時にグラフを再描画
      this.showGraph();
    },
    loading() {
      // 読込中かどうか
      this.$emit("update:loading", this.loading);
    },
  },
  mounted() {},
  methods: {
    // グラフ描写
    async showGraph() {
      this.loading = true;

      // グラフデータを初期化
      this.chart.series[0].data = [];
      this.chart.series[1].data = [];

      // 表示期間の開始・終了
      const displayPeriodFrom = moment(this.date);
      const displayPeriodTo = moment(displayPeriodFrom).add(6, "days");

      // 表示期間の開始の記録を取得
      const records = {};

      let recordDocs = await getDoc(
        doc(
          this.$firestore,
          "users",
          this.patientUid,
          "records",
          displayPeriodFrom.format("YYYYMM")
        )
      );

      this.formatRecordObjectKey(records, recordDocs, displayPeriodFrom);

      // 表示期間が月をまたいでいる場合は記録を追加取得
      if (displayPeriodFrom.month() !== displayPeriodTo.month()) {
        recordDocs = await getDoc(
          doc(
            this.$firestore,
            "users",
            this.patientUid,
            "records",
            displayPeriodTo.format("YYYYMM")
          )
        );
        this.formatRecordObjectKey(records, recordDocs, displayPeriodTo);
      }

      // 表示期間の記録をグラフに追加
      for (let i = 0; i < 7; i++) {
        const key = displayPeriodFrom.format("YYYYMMDD");
        this.addGraphData(displayPeriodFrom, records[key]);

        displayPeriodFrom.add(1, "days");
      }

      this.$nextTick(() => {
        this.loading = false;
      });
    },

    // 記録のオブジェクトキーを変換（DD → YYYYMMDD）
    formatRecordObjectKey(records, recordDocs, displayPeriod) {
      for (let i = 1; i <= displayPeriod.daysInMonth(); i++) {
        const dd = `${i}`.padStart(2, "0");
        records[`${displayPeriod.format("YYYYMM")}${dd}`] = recordDocs.data()?.[dd] ?? null;
      }
    },

    // グラフデータ（日・記録）を追加
    addGraphData(date, record) {
      // 最低血圧（朝と夜）
      this.chart.series[0].data.push({
        x: moment(date).format("YYYY-MM-DD HH:mm"),
        y: record?.morning?.diastolicPressure || null,
      });
      this.chart.series[0].data.push({
        x: moment(date).add(6, "hours").format("YYYY-MM-DD HH:mm"),
        y: record?.night?.diastolicPressure || null,
      });

      // 最高血圧（朝と夜）
      this.chart.series[1].data.push({
        x: moment(date).format("YYYY-MM-DD HH:mm"),
        y: record?.morning?.systolicPressure || null,
      });
      this.chart.series[1].data.push({
        x: moment(date).add(6, "hours").format("YYYY-MM-DD HH:mm"),
        y: record?.night?.systolicPressure || null,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
