import { collection, onSnapshot, query, where } from "firebase/firestore";
import { mapActions, mapState } from "vuex";

export default function () {
  return {
    computed: {
      ...mapState("user", ["selfUser"]),
      ...mapState("member", ["members"]),
    },
    methods: {
      ...mapActions("member", ["addMember", "deleteMember"]),

      // usersからメンバー情報を購読
      subscribeMembers() {
        try {
          const db = this.$firestore;

          // typeがmemberかつログインユーザーと同じ病院に所属しているuserを取得
          const q = query(
            collection(db, "users"),
            where("type", "==", "member"),
            where("hospitalIds", "array-contains-any", this.selfUser.hospitalIds)
          );
          return onSnapshot(
            q,
            (snapshot) => {
              snapshot.docChanges().forEach((change) => {
                const doc = change.doc;

                switch (change.type) {
                  // メンバー追加,メンバー更新
                  case "added":
                  case "modified":
                    this.addMember({ uid: doc.id, ...doc.data() });
                    break;
                  // メンバー削除
                  case "removed":
                    this.deleteMember({ uid: doc.id });
                    break;
                }
              });
            },
            () => {
              location.reload();
            }
          );
        } catch (error) {
          // TODO: エラーハンドリング
          console.error("Firestore エラー:", error);
        }
      },
    },
  };
}
