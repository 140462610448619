<template>
  <v-container class="h-full text-left">
    <div v-for="(record, index) in records" class="space-y-2" :key="index">
      <!-- 日付 -->
      <div class="text-primary font-bold">
        {{ moment(record.date).format("YYYY年MM月D日（ddd）") }}
      </div>
      <v-row class="ml-2" dense>
        <!-- 体重 -->
        <v-col cols="12">体重: <span :class="getTextColor(record?.signs?.weightIncrease?.signed)"> {{ record?.weight ??
          "-" }}</span> kg
          <v-icon v-if="record?.signs?.weightIncrease?.signed" class="pb-1" small color="red">mdi-alert</v-icon>
        </v-col>
        <!-- 血圧 -->
        <v-col cols="12">
          朝の血圧（脈拍）:
          <span class="text-primary font-bold">{{ record?.morning?.systolicPressure ?? "-" }}</span>
          /
          <span class="text-primary font-bold">{{
            record?.morning?.diastolicPressure ?? "-"
          }}</span>
          （<span :class="getTextColor(record?.signs?.heartRateMaxMorning?.signed)">{{ record?.morning?.heartRate ?? "-"
            }}</span>）
          <v-icon v-if="record?.signs?.heartRateMaxMorning?.signed" class="pb-1 -ml-2.5" small
            color="red">mdi-alert</v-icon>
        </v-col>
        <v-col cols="12">
          夜の血圧（脈拍）:
          <span class="text-primary font-bold">{{ record?.night?.systolicPressure ?? "-" }}</span>
          /
          <span class="text-primary font-bold">{{ record?.night?.diastolicPressure ?? "-" }}</span>
          （<span :class="getTextColor(record?.signs?.heartRateMaxNight?.signed)">{{ record?.night?.heartRate ?? "-"
            }}</span>）
          <v-icon v-if="record?.signs?.heartRateMaxNight?.signed" class="pb-1 -ml-2.5" small color="
            red">mdi-alert</v-icon>
        </v-col>

        <!-- 服薬 -->
        <v-col cols="6">
          服薬（朝）:
          <span class="text-primary font-bold">
            {{ medicationChecks(record?.medicationChecks ?? null, "morning") }}</span>
        </v-col>
        <v-col cols="6">
          服薬（昼）:
          <span class="text-primary font-bold">
            {{ medicationChecks(record?.medicationChecks ?? null, "evening") }}</span>
        </v-col>
        <v-col cols="6">
          服薬（晩）:
          <span class="text-primary font-bold">
            {{ medicationChecks(record?.medicationChecks ?? null, "noon") }}</span>
        </v-col>
        <v-col cols="6">
          服薬（寝る前）:
          <span class="text-primary font-bold">
            {{ medicationChecks(record?.medicationChecks ?? null, "bedtime") }}</span>
        </v-col>

        <!-- 自覚症状 -->
        <v-col cols="6">
          安静時の息切れ:
          <span :class="getTextColor(record?.signs?.restingDyspnea?.signed)">
            {{ getSymptomsLabel(record?.symptoms?.restingDyspnea ?? null) }}</span>
          <v-icon v-if="record?.signs?.restingDyspnea?.signed" class="pb-1" small color="red">mdi-alert</v-icon>
        </v-col>
        <v-col cols="6">
          労作時の息切れ:
          <span class="text-primary font-bold">
            {{ getSymptomsLabel(record?.symptoms?.exertionalDyspnea ?? null) }}</span>
        </v-col>
        <v-col cols="6">
          むくみ:
          <span class="text-primary font-bold">
            {{ getSymptomsLabel(record?.symptoms?.swelling ?? null) }}</span>
        </v-col>
        <v-col cols="6">
          疲れやすさ:
          <span class="text-primary font-bold">
            {{ getSymptomsLabel(record?.symptoms?.tiredness ?? null) }}</span>
        </v-col>
        <v-col cols="6">
          食欲低下:
          <span class="text-primary font-bold">
            {{ getSymptomsLabel(record?.symptoms?.lossOfAppetite ?? null) }}</span>
        </v-col>
        <v-col cols="6">
          不眠:
          <span class="text-primary font-bold">
            {{ getSymptomsLabel(record?.symptoms?.insomnia ?? null) }}</span>
        </v-col>
        <!-- 運動 -->
        <v-col cols="6">
          <div>
            運動:
            <span class="text-primary font-bold">
              {{ getExerciseLabel(record?.exercise ?? null) }}</span>
          </div>
        </v-col>
      </v-row>
      <v-divider v-if="lastRecord(index)" class="my-4 px-0"></v-divider>
    </div>
  </v-container>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
export default {
  name: "RecordList",
  props: {
    // 日付
    date: {
      required: true,
    },
    // 患者uid
    patientUid: {
      required: true,
    },
  },
  data: () => ({
    // 記録
    records: [],
  }),
  computed: {},
  watch: {
    date() {
      this.getRecords();
    },
  },
  mounted() {
    this.getRecords();
  },
  created() { },
  methods: {
    // データ取得
    async getRecords() {
      this.records = [];
      // 選択された月
      const selectedDate = moment(this.date).format("YYYYMM");
      const getRecords = await getDoc(
        doc(this.$firestore, "users", this.patientUid, "records", selectedDate)
      );

      // システム日付(クライアント側取得)
      const systemDate = moment();
      const systemMonth = systemDate.format("YYYYMM");
      // システム日付の月を表示する場合はシステム日付までの表示
      const maxDisplayDate =
        systemMonth === selectedDate ? systemDate.get("date") : moment(this.date).daysInMonth();

      for (let i = 1; i <= maxDisplayDate; i++) {
        const dd = `${i}`.padStart(2, "0");
        const record = getRecords.data()?.[dd] || {};
        this.records.push({ date: `${selectedDate}${dd}`, ...record });
      }

      // 降順並びに
      this.records.reverse();
    },
    // 服薬チェック
    medicationChecks(checkList, time) {
      return checkList && checkList.includes(time) ? "◯" : "-";
    },
    // テキストカラーを設定
    getTextColor(signed) {
      if (signed) {
        return "text-red-500 font-bold";
      } else {
        return "text-primary font-bold";
      }
    },
    // 自覚症状
    getSymptomsLabel(value) {
      switch (value) {
        case null:
          return "未選択";
        case 0:
          return "なし";
        case 1:
          return "あり";
      }
    },
    // 運動
    getExerciseLabel(value) {
      switch (value) {
        case null:
          return "未選択";
        case 0:
          return "してない";
        case 1:
          return "した";
      }
    },
    // 最後の記録か
    lastRecord(index) {
      return index < moment(this.date).endOf("month").format("YYYYMMDD");
    },
  },
};
</script>

<style></style>
