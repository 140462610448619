<template>
  <div v-resize="setContentHeight">
    <!-- メンバー一覧 -->
    <v-data-table v-if="displayMode == DIALOG_DISPLAY_MODE.DETAIL" :headers="detailHeaders" :items="selectedMembers"
      :items-per-page="-1" sort-by="nameKana" hide-default-footer @click:row="(item) => {
        showMemberDialog(item);
      }
        ">
      <!-- 性別 -->
      <template #[`item.gender`]="{ item }">
        {{ GENDER_LABEL[item.gender] }}
      </template>
    </v-data-table>

    <div v-if="displayMode == DIALOG_DISPLAY_MODE.UPDATE" class="pb-4">
      <v-data-table v-model="selectedMembers" :headers="updateHeaders" :items="filteredMemberCandidates"
        :height="contentHeight" :items-per-page="-1" hide-default-footer class="cursor-pointer" show-select
        item-key="uid" sort-by="nameKana" @click:row="(item) => {
          showMemberDialog(item);
        }
          ">
        <!-- 性別 -->
        <template #[`item.gender`]="{ item }">
          {{ GENDER_LABEL[item.gender] }}
        </template>
      </v-data-table>
    </div>

    <!-- メンバー詳細ダイアログ -->
    <v-dialog v-if="memberDialog.show" v-model="memberDialog.show" width="800" persistent no-click-animation>
      <member-dialog v-bind="memberDialog.params" @update="updateMember"
        @close="memberDialog.show = false"></member-dialog>
    </v-dialog>
  </div>
</template>

<script>
import Member from "@/components/dialogs/Member";
import { DIALOG_DISPLAY_MODE, GENDER_LABEL } from "@/const/const";
import patientMixin from "@/mixins/patientMixin";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MemberList",
  mixins: [patientMixin()],
  components: { MemberDialog: Member },
  props: {
    // ダイアログ表示モード
    displayMode: {
      required: true,
    },
    // 患者UID
    patientUid: {
      required: true,
    },
    // 検索する病院
    searchHospitalIds: {
      required: false,
      default: () => [],
    },
    // 検索キーワード
    searchKeyword: {
      required: false,
      default: "",
    },
  },
  data: () => ({
    // コンテンツの高さ
    contentHeight: 0,

    // 性別のラベル
    GENDER_LABEL,

    // ヘッダー
    detailHeaders: [
      { text: "病院名", value: "hospitalNames" },
      { text: "氏名", value: "name" },
      { text: "氏名（かな）", value: "nameKana" },
      { text: "生年月日", value: "displayDateOfBirth" },
      { text: "性別", value: "gender" },
      { text: "電話番号", value: "phoneNumber" },
      { text: "メールアドレス", value: "email" },
    ],
    updateHeaders: [
      { text: "病院名", value: "hospitalNames" },
      { text: "氏名", value: "name" },
      { text: "氏名（かな）", value: "nameKana" },
      { text: "生年月日", value: "displayDateOfBirth" },
      { text: "性別", value: "gender" },
    ],

    // メンバー詳細ダイアログ
    memberDialog: {
      show: false,
      params: {
        member: null,
      },
    },

    // メンバー候補
    memberCandidates: [],

    // 選択されているメンバー
    selectedMembers: [],

    // 表示モード
    DIALOG_DISPLAY_MODE,
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 患者情報
    ...mapState("patient", ["patients"]),
    // メンバー情報
    ...mapState("member", ["members"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    patient() {
      return this.getPatient(this.patientUid);
    },
    // 複数病院に所属しているかどうか
    hasMultiHospitals() {
      return this.selfUser?.hospitalIds.length > 1;
    },
    // フィルター済みのメンバー候補
    filteredMemberCandidates() {
      // 一覧の表示形式に合わせて整形
      let formatedMemberCandidates = this.memberCandidates.map((memberCandidate) => {

        // メンバーの病院IDに紐づく病院名の取得
        const hospitalNames = memberCandidate.hospitalIds.map((hospitalId) => {
          const hospital = this.hospitals.find((hospital) => hospital.id == hospitalId);
          return hospital?.name;
        });

        return {
          ...memberCandidate,
          hospitalNames,
          name: `${memberCandidate.lastName} ${memberCandidate.firstName}`,
          nameKana: `${memberCandidate.lastNameKana} ${memberCandidate.firstNameKana}`,
          displayDateOfBirth: moment(memberCandidate.dateOfBirth.toDate()).format("YYYY年MM月DD日"),
        };
      });

      // 病院でフィルター
      if (this.searchHospitalIds.length > 0) {
        formatedMemberCandidates = formatedMemberCandidates.filter((doctorCandidate) =>
          doctorCandidate.hospitalIds.some((hospitalId) =>
            this.searchHospitalIds.includes(hospitalId)
          )
        );
      }

      // キーワードでフィルター（半角スペース区切りのキーワードで、氏名・かなを AND 検索）
      let filteredMemberCandidates = [...formatedMemberCandidates];
      const filterKeywords = this.searchKeyword.replace(/[\x20\u3000]/g, " ").split(" ");

      filterKeywords.forEach((filterKeyword) => {
        filteredMemberCandidates = filteredMemberCandidates.filter((memberCandidate) => {
          const { name, nameKana } = memberCandidate;
          return name.includes(filterKeyword) || nameKana.includes(filterKeyword);
        });
      });
      this.$emit("update:filteredMemberLength", filteredMemberCandidates.length);
      return filteredMemberCandidates;
    },
  },
  created() {
    // メンバー候補を設定
    this.setMemberCandidates();

    if (!this.hasMultiHospitals) {
      // 病院名列を除く
      this.detailHeaders = this.detailHeaders.filter(header => header.value !== "hospitalNames");
      this.updateHeaders = this.updateHeaders.filter(header => header.value !== "hospitalNames");
    }
  },
  methods: {
    // コンテンツの高さを設定
    setContentHeight() {
      this.contentHeight = window.innerHeight - 380;
    },
    // メンバー候補を設定
    setMemberCandidates() {
      const memberCandidates = [...this.patients, ...this.members];

      // 患者自身を除外する
      this.memberCandidates = memberCandidates.filter(
        (memberCandidate) => memberCandidate.uid != this.patientUid
      );

      // 割当済みのメンバーを選択
      this.selectedMembers.splice(0);

      this.filteredMemberCandidates.forEach((filteredMemberCandidate) => {
        if (this.patient.memberUids.includes(filteredMemberCandidate.uid)) {
          this.selectedMembers.push(filteredMemberCandidate);
        }
      });
    },

    // メンバー詳細ダイアログを表示
    showMemberDialog(member) {
      this.memberDialog.params = {
        member,
        displayMode: DIALOG_DISPLAY_MODE.DETAIL,
      };
      this.memberDialog.show = true;
    },

    // メンバーを更新
    updateMember() {
      this.setMemberCandidates();
      this.memberDialog.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// メンバー一覧
.v-data-table {
  @apply border;
}

::v-deep thead {
  @apply text-nowrap;
}

::v-deep tbody>tr:last-child>td {
  @apply border-b;
  @apply truncate;
}
</style>
