<template>
  <v-card class="p-4">
    <v-card-title>
      <!-- ダイアログタイトル -->
      <div>担当医割当</div>

      <v-spacer></v-spacer>

      <!-- 閉じるボタン -->
      <v-btn icon @click="$emit('close')">
        <v-icon large color="primary">mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>

    <!-- 検索フォーム -->
    <div class="px-4 h-16 flex items-center space-x-8">
      <!-- 病院名 -->
      <div v-if="hasMultiHospitals" class="flex items-center space-x-4">
        <div>病院名</div>
        <div class="w-64">
          <v-select
            v-model="searchHospitalIds"
            :items="selfUserHospitals"
            item-value="id"
            item-text="name"
            multiple
            outlined
            dense
            hide-details
          >
          </v-select>
        </div>
      </div>

      <!-- キーワード -->
      <div class="flex items-center space-x-4">
        <div>キーワード</div>
        <div class="w-64">
          <v-text-field
            v-model="searchKeyword"
            placeholder="氏名・氏名（かな）"
            autofocus
            outlined
            dense
            hide-details
          >
            <v-icon slot="append" @click="clearKeyword">mdi-close</v-icon>
          </v-text-field>
        </div>
      </div>
    </div>

    <!-- 医師一覧 -->
    <div class="flex items-center justify-space-between mb-4">
      <!-- 担当医候補一覧 -->
      <div>全{{ doctors.length }}件中/{{ filteredDoctorLength }}件を表示</div>

      <!-- 医師登録ボタン -->
      <v-btn color="primary" elevation="0" @click="clickRegisterDoctor">医師登録</v-btn>
    </div>

    <!-- 担当医候補一覧 -->
    <responsible-doctor-list
      ref="refsample"
      :key="doctorListUpdateKey"
      :patientUid="patientUid"
      :searchHospitalIds="searchHospitalIds"
      :searchKeyword="searchKeyword"
      :displayMode="DIALOG_DISPLAY_MODE.UPDATE"
      @update:filteredDoctorLength="setFilteredDoctorLength"
    >
    </responsible-doctor-list>

    <!-- ボタンエリア -->
    <div class="flex justify-end space-x-4">
      <!-- キャンセルボタン -->
      <v-btn elevation="0" @click="$emit('close')"> キャンセル </v-btn>

      <!-- 更新ボタン -->
      <v-btn
        elevation="0"
        color="primary"
        @click="assignDoctorsToPatientFunction"
        :disabled="isProcessing"
      >
        更新
      </v-btn>
    </div>

    <!-- 医師ダイアログ -->
    <v-dialog
      v-if="doctorDialog.show"
      v-model="doctorDialog.show"
      width="800"
      persistent
      no-click-animation
    >
      <doctor-dialog
        v-bind="doctorDialog.params"
        @complete="completeDoctorRegister"
        @close="doctorDialog.show = false"
      ></doctor-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
import Doctor from "@/components/dialogs/Doctor";
import ResponsibleDoctorList from "@/components/organisms/ResponsibleDoctorList";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import patientMixin from "@/mixins/patientMixin";
import { mapActions, mapState } from "vuex";
export default {
  name: "DoctorAssignDialog",
  components: { ResponsibleDoctorList, DoctorDialog: Doctor },
  mixins: [patientMixin(), errorHandlerMixin()],
  props: {
    // 患者UID
    patientUid: {
      required: true,
    },
  },
  data: () => ({
    // データベース
    db: null,
    // ヘッダー
    headers: [
      { text: "病院名", value: "hospitalNames" },
      { text: "氏名", value: "name" },
      { text: "氏名（かな）", value: "nameKana" },
    ],
    // 検索する病院
    searchHospitalIds: [],
    // 検索キーワード
    searchKeyword: "",
    // 表示モード
    DIALOG_DISPLAY_MODE,
    // 検索結果の件数
    filteredDoctorLength: 0,
    // 医師ダイアログ
    doctorDialog: {
      show: false,
      params: {},
    },
    // 担当医一覧を任意のタイミングで更新するためのキー
    doctorListUpdateKey: 1,
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 医師情報
    ...mapState("doctor", ["doctors"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    patient() {
      return this.getPatient(this.patientUid);
    },
    // 複数病院に所属しているかどうか
    hasMultiHospitals() {
      return this.selfUser?.hospitalIds.length > 1;
    },
    // ログインユーザの所属病院情報取得
    selfUserHospitals() {
      return this.selfUser.hospitalIds.map((hospitalId) =>
        this.hospitals.find((hospital) => hospital.id == hospitalId)
      );
    },
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // 担当医割当更新
    async assignDoctorsToPatientFunction() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        const doctorUids = this.$refs.refsample["selectedDoctors"].map((select) => select.uid);

        // 担当医割当更新API実行
        const assignDoctorsToPatientFunction = this.$httpsCallable(
          this.$functions,
          "assigndoctorstopatient"
        );
        await assignDoctorsToPatientFunction({ uid: this.patientUid, doctorUids });

        this.decrementRunningApiCount();

        this.$store.dispatch("snackbar/openSnackbar", {
          text: `担当医割当を更新しました`,
          color: "success",
        });

        this.$emit("complete");
      } catch (error) {
        this.showError(`担当医割当の更新に失敗しました`);
      } finally {
        this.isProcessing = false;
      }
    },
    // フィルタされたメンバーの件数を設定
    setFilteredDoctorLength(filteredDoctorLength) {
      this.filteredDoctorLength = filteredDoctorLength;
    },
    // 検索キーワードのクリア
    clearKeyword() {
      this.$data.searchKeyword = "";
    },

    // 医師登録ボタン押下
    clickRegisterDoctor() {
      this.doctorDialog.params.displayMode = DIALOG_DISPLAY_MODE.REGISTER;
      this.doctorDialog.show = true;
    },

    // 医師登録を完了
    completeDoctorRegister() {
      this.doctorListUpdateKey++;
      this.doctorDialog.show = false;
    },
  },
};
</script>
