import Vue from "vue";
import Vuex from "vuex";
import api from "./modules/api";
import doctor from "./modules/doctor";
import hospital from "./modules/hospital";
import member from "./modules/member";
import patient from "./modules/patient";
import snackbar from "./modules/snackbar";
import unsubscribe from "./modules/unsubscribe";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    user,
    hospital,
    doctor,
    patient,
    member,
    snackbar,
    unsubscribe,
  },
  actions: {
    resetState({ dispatch }) {
      return Promise.all([
        dispatch("user/resetState"),
        dispatch("hospital/resetState"),
        dispatch("doctor/resetState"),
        dispatch("patient/resetState"),
        dispatch("member/resetState"),
      ]).catch((error) => {
        // TODO:エラーハンドリング
        console.log(error);
      });
    },
  },
});
