import Vue from "vue";
import ja from "vuetify/es5/locale/ja";
import Vuetify from "vuetify/lib/framework";

const customJa = {
  ...ja,
  noDataText: "データがありません",
};

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ja: customJa },
    current: "ja",
  },
  theme: {
    themes: {
      light: {
        primary: "#39618c",
      },
    },
  },
});
