<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-card class="p-4 space-y-4">
      <v-card-title>
        <!-- ダイアログタイトル -->
        <div>メールアドレス変更コード確認</div>

        <v-spacer></v-spacer>

        <!-- 閉じるボタン -->
        <v-btn icon large @click="$emit('close')">
          <v-icon large color="primary">mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="h-full flex justify-center">
        <div class="flex flex-col">
          <!-- メッセージ -->
          <div class="mb-4">
            <div>メールアドレス変更コードをメールでお送りしました。</div>
            <div>
              30分以内にメールアドレス変更コードを入力し、メールアドレスを変更してください。
            </div>
            <!-- 注釈 -->
            <div class="mt-4 mb-1">【メールが届かない場合】</div>

            <ul class="list-disc">
              <li>入力いただいたメールアドレスに間違いがないかご確認ください</li>
              <li>
                迷惑メール設定をされている場合、<span class="text-rose-500 font-bold"
                  >@heart-diary.com</span
                >
                の受信許可設定をお願いします。
              </li>
              <li>
                上記ご対応をいただいてもメールが届かない場合には画面下部よりお問い合せください。
              </li>
            </ul>
          </div>

          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">コード<span class="ml-1 red--text">*</span></div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="コード" rules="required">
                <v-text-field
                  class=""
                  v-model="code"
                  type="text"
                  outlined
                  dense
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </div>
          </div>

          <div class="flex justify-end">
            <v-btn
              color="primary"
              elevation="0"
              :disabled="invalid || isProcessing"
              @click="sendEmailChangeMail"
            >
              変更
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";

export default {
  name: "EmailChangeConfirm",
  mixins: [errorHandlerMixin()],
  props: {
    codeId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    // コード
    code: "",
    // 処理中フラグ
    isProcessing: false,
  }),
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // メールアドレス変更処理実施
    async sendEmailChangeMail() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // コードを送付して、メールアドレス変更処理を実施
        const func = this.$httpsCallable(this.$functions, "emailchangeconfirm");
        await func({
          codeId: this.codeId,
          code: this.code,
        });

        this.decrementRunningApiCount();

        this.openSnackbar({ text: "メールアドレスを変更しました" });

        this.$emit("emailChanged");
      } catch (error) {
        let message = "";
        switch (error.message) {
          case "mail-change/code-expired":
            message = MESSAGES.ERRORS.CODE_EXPIRED;
            break;
          case "mail-change/code-failed":
            message = MESSAGES.ERRORS.CODE_FAILED;
            break;
          default:
            message = MESSAGES.ERRORS.UNEXPECTED;
        }
        this.showError(message);
        this.decrementRunningApiCount();
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
