<template>
  <div class="flex items-center justify-center" style="height: 250px">
    <v-progress-circular
      v-if="loading"
      color="primary"
      size="40"
      indeterminate
    ></v-progress-circular>
    <template v-else>
      <apexchart
        v-if="chart.series[0].data.length > 0"
        class="w-full"
        type="line"
        height="250px"
        :options="chart.options"
        :series="chart.series"
      ></apexchart>
      <div v-else>データがありません</div>
    </template>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "WeightGraph",
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    // 日付
    date: {
      required: true,
    },
    // 患者uid
    patientUid: {
      required: true,
    },
  },
  data: () => ({
    // 読込中か
    loading: null,

    // 折れ線グラフ
    chart: {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: true,
          hideEmptySeries: true,
          custom({ series, seriesIndex, dataPointIndex }) {
            if (series[seriesIndex][dataPointIndex] == null) {
              return null;
            }

            return `<div class="py-2 px-4"><span class="text-primary font-bold">${series[seriesIndex][dataPointIndex]}</span> kg</div>`;
          },
        },
        grid: {
          padding: {
            right: 30,
          },
        },
        stroke: {
          width: 3,
        },
        markers: {
          size: 4,
        },
        yaxis: {
          stepSize: 1,
          max: (max) => Math.ceil(max + 1),
          min: (min) => Math.floor(min - 1),
        },
        xaxis: {
          categories: [],
          labels: {
            formatter(value) {
              return value;
            },
          },
          tooltip: {
            enabled: true,
            formatter(value) {
              return `${value}日`;
            },
          },
        },
        annotations: {},
      },
      series: [
        {
          name: "",
          data: [],
          color: "#39618c",
        },
      ],
    },
  }),
  computed: {},
  watch: {
    date() {
      // 日付変更時にグラフを再描画
      this.showGraph();
    },
    loading() {
      this.$emit("update:loading", this.loading);
    },
  },
  mounted() {
    this.showGraph();
  },

  methods: {
    // グラフ描写
    async showGraph() {
      this.loading = true;

      // グラフデータを初期化
      this.chart.series[0].data = [];

      try {
        const records = await getDoc(
          doc(
            this.$firestore,
            "users",
            this.patientUid,
            "records",
            moment(this.date).format("YYYYMM")
          )
        );

        if (Object.keys(records.data() || {}).length > 0) {
          for (let i = 1; i <= moment(this.date).daysInMonth(); i++) {
            const dd = `${i}`.padStart(2, "0");
            this.chart.series[0].data.push(records.data()[dd]?.weight || null);
          }
        }
      } catch (error) {
        // TODO: エラーハンドリング
        console.error("Firestore エラー:", error);
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
