const getters = {};

const getDefaultState = () => {
  return {
    // 患者情報
    patients: [],
  };
};

const state = getDefaultState();

const mutations = {
  // 患者情報を追加
  addPatient: (state, { uid, patient }) => {
    state.patients.push({ uid, ...patient });
  },

  // ユーザー情報を設定
  setPatientInfo: (state, { uid, patientInfo }) => {
    const index = state.patients.findIndex((patient) => patient.uid == uid);

    if (index != -1) {
      state.patients.splice(index, 1, { ...state.patients[index], ...patientInfo });
    }
  },

  // 今日の記録を設定
  setTodayRecord: (state, { uid, todayRecord }) => {
    const index = state.patients.findIndex((patient) => patient.uid == uid);

    if (index != -1) {
      state.patients.splice(index, 1, { ...state.patients[index], todayRecord });
    }
  },

  // 未読メッセージ数を設定
  setUnreadMessageCount: (state, { uid, unreadMessageCount }) => {
    const index = state.patients.findIndex((patient) => patient.uid == uid);

    if (index != -1) {
      state.patients.splice(index, 1, { ...state.patients[index], unreadMessageCount });
    }
  },

  // 最終既読日時を設定
  setLastReadDatetime: (state, { uid, lastReadDatetime }) => {
    const index = state.patients.findIndex((patient) => patient.uid == uid);

    if (index != -1) {
      state.patients.splice(index, 1, { ...state.patients[index], lastReadDatetime });
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addPatient: ({ commit }, { uid, patient, todayRecord, unreadMessageCount, lastReadDatetime }) => {
    commit("addPatient", { uid, patient, todayRecord, unreadMessageCount, lastReadDatetime });
  },
  setPatientInfo: ({ commit }, { uid, patientInfo }) => {
    commit("setPatientInfo", { uid, patientInfo });
  },
  setTodayRecord: ({ commit }, { uid, todayRecord }) => {
    commit("setTodayRecord", { uid, todayRecord });
  },
  setUnreadMessageCount: ({ commit }, { uid, unreadMessageCount }) => {
    commit("setUnreadMessageCount", { uid, unreadMessageCount });
  },
  setLastReadDatetime: ({ commit }, { uid, lastReadDatetime }) => {
    commit("setLastReadDatetime", { uid, lastReadDatetime });
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
