<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Dialog @close="$emit('close')">
      <!-- タイトル -->
      <template #[`title`]>
        {{ title }}
      </template>

      <!-- コンテンツ -->
      <template #[`contents`]>
        <!-- 病院名 -->
        <div v-if="selfUser?.hospitalIds.length > 1" class="h-16 flex items-center">
          <div class="w-64 pb-6">病院名<span v-if="!readonly" class="ml-1 red--text">*</span></div>
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="病院名" rules="required">
              <v-select
                v-model="patient.hospitalIds"
                :items="hospitals"
                item-value="id"
                item-text="name"
                multiple
                outlined
                dense
                :error-messages="errors"
              >
              </v-select>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">氏名<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <!-- 姓 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="姓" rules="required|max:10">
              <v-text-field
                class="mr-4"
                v-model="patient.lastName"
                placeholder="姓"
                outlined
                dense
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- 名 -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="名" rules="required|max:10">
              <v-text-field
                v-model="patient.firstName"
                placeholder="名"
                outlined
                dense
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 氏名(かな) -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">
            氏名（かな）<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>

          <!-- せい -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="せい" rules="required|hiragana|max:20">
              <v-text-field
                class="mr-4"
                v-model="patient.lastNameKana"
                placeholder="せい"
                outlined
                dense
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>

          <!-- めい -->
          <div class="flex-auto">
            <validation-provider v-slot="{ errors }" name="めい" rules="required|hiragana|max:20">
              <v-text-field
                v-model="patient.firstNameKana"
                placeholder="めい"
                outlined
                dense
                :error-messages="errors"
              >
              </v-text-field>
            </validation-provider>
          </div>
        </div>

        <!-- 性別 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">性別<span v-if="!readonly" class="ml-1 red--text">*</span></div>

          <validation-provider v-slot="{ errors }" name="性別" rules="required">
            <v-radio-group class="mt-0" v-model="patient.gender" row :error-messages="errors">
              <v-radio
                v-for="gender in GENDERS"
                :key="gender.value"
                :label="gender.label"
                :value="gender.value"
              >
              </v-radio>
            </v-radio-group>
          </validation-provider>
        </div>

        <!-- 生年月日 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">
            生年月日<span v-if="!readonly" class="ml-1 red--text">*</span>
          </div>
          <DateOfBirthPicker v-model="selectedDate" />
        </div>
        <!-- 住所 -->
        <div class="h-16 pb-6 flex items-center">住所</div>

        <!-- 住所詳細 -->
        <div class="pl-8">
          <!-- 郵便番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              郵便番号（ハイフンなし）<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="w-24">
              <validation-provider v-slot="{ errors }" name="郵便番号" rules="required|digits:7">
                <v-text-field v-model="patient.postCode" outlined dense :error-messages="errors">
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 都道府県 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              都道府県<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="w-32">
              <validation-provider v-slot="{ errors }" name="都道府県" rules="required">
                <v-select
                  v-model="patient.prefectureCode"
                  :items="PREFECTURES"
                  item-value="value"
                  item-text="label"
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-select>
              </validation-provider>
            </div>
          </div>

          <!-- 市区町村 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              市区町村<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="市区町村" rules="required|max:30">
                <v-text-field
                  v-model="patient.municipality"
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 番地 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">番地<span v-if="!readonly" class="ml-1 red--text">*</span></div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="番地" rules="required|max:20">
                <v-text-field
                  v-model="patient.addressNumber"
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 建物名・部屋番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">建物名・部屋番号</div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="建物名・部屋番号" rules="max:50">
                <v-text-field
                  v-model="patient.buildingNameAndRoomNumber"
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>

        <!-- 連絡先 -->
        <div class="h-16 pb-6 flex items-center">連絡先</div>

        <!-- 連絡先詳細 -->
        <div class="pl-8">
          <!-- メールアドレス -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              メールアドレス<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="メールアドレス" rules="required|email">
                <v-text-field
                  v-model="patient.email"
                  type="email"
                  outlined
                  dense
                  :disabled="isUpdateMode"
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- 電話番号 -->
          <div class="h-16 flex items-center">
            <div class="w-56 pb-6">
              電話番号（ハイフンなし）<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider
                v-slot="{ errors }"
                name="電話番号"
                rules="required|numeric|max:11"
              >
                <v-text-field
                  v-model="patient.phoneNumber"
                  type="tel"
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>

        <div v-if="displayMode == DIALOG_DISPLAY_MODE.REGISTER">
          <!-- パスワード -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード<span v-if="!readonly" class="ml-1 red--text">*</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <div>
                  パスワードは以下を満たす必要があります
                  <ul class="list-disc">
                    <li>{{ PASSWORD_MIN_LENGTH }}文字以上</li>
                    <li>英字・数字・記号を含む</li>
                    <li>使用可能な記号{{ PASSWORD_ENABLE_SYMBOLS }}</li>
                  </ul>
                </div>
              </v-tooltip>
            </div>
            <div class="flex-auto">
              <validation-provider v-slot="{ errors }" name="パスワード" rules="required|password">
                <v-text-field
                  v-model="patient.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  dense
                  autoComplete="new-password"
                  solo
                  flat
                  outlined
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>

          <!-- パスワード（確認用） -->
          <div class="h-16 flex items-center">
            <div class="w-64 pb-6">
              パスワード（確認用）<span v-if="!readonly" class="ml-1 red--text">*</span>
            </div>
            <div class="flex-auto">
              <validation-provider
                v-slot="{ errors }"
                name="パスワード（確認用）"
                :rules="`required|password|password_confirm:${patient.password}`"
              >
                <v-text-field
                  v-model="patient.passwordConfirm"
                  :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPasswordConfirm ? 'text' : 'password'"
                  dense
                  outlined
                  :error-messages="errors"
                  @click:append="showPasswordConfirm = !showPasswordConfirm"
                >
                </v-text-field>
              </validation-provider>
            </div>
          </div>
        </div>

        <!-- 病気 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">病気</div>
          <div class="flex-auto flex items-center">
            <div class="w-full">
              <v-text-field
                :value="
                  patient.diseases.length > 0
                    ? patient.diseases.map((disease) => disease.name).join(', ')
                    : '現在登録されている病気はありません'
                "
                type="text"
                outlined
                dense
                disabled
                readonly
              />
            </div>
            <div class="pb-6">
              <v-tooltip top>
                <template #[`activator`]="{ attrs, on }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="diseaseDialog.show = true">
                    <v-icon>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <div>編集</div>
              </v-tooltip>
            </div>
          </div>
        </div>

        <!-- 病気編集ダイアログ -->
        <v-dialog v-model="diseaseDialog.show" max-width="720" persistent no-click-animation>
          <disease
            :key="diseaseDialog.updateKey"
            :diseases="patient.diseases"
            @set="setDiseases"
            @close="resetDiseases"
          />
        </v-dialog>

        <!-- 薬 -->
        <div class="h-16 flex items-center">
          <div class="w-64 pb-6">薬</div>
          <div class="flex-auto flex items-center">
            <div class="w-full">
              <v-text-field
                :value="
                  patient.medications.length > 0
                    ? patient.medications.map((medication) => medication.name).join(', ')
                    : '現在登録されている薬はありません'
                "
                type="text"
                outlined
                dense
                disabled
                readonly
              />
            </div>
            <div class="pb-6">
              <v-tooltip top>
                <template #[`activator`]="{ attrs, on }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="medicationDialog.show = true">
                    <v-icon>mdi-table-edit</v-icon>
                  </v-btn>
                </template>
                <div>編集</div>
              </v-tooltip>
            </div>
          </div>
        </div>

        <!-- 薬編集ダイアログ -->
        <v-dialog v-model="medicationDialog.show" max-width="720" persistent no-click-animation>
          <medication
            :key="medicationDialog.updateKey"
            :medications="patient.medications"
            @set="setMedications"
            @close="resetMedications"
          />
        </v-dialog>

        <!-- 備考 -->
        <div class="h-16 flex">
          <div class="w-64 pb-6">備考</div>
          <div class="flex-auto">
            <v-textarea v-model="patient.note" placeholder="" outlined dense rows="3"></v-textarea>
          </div>
        </div>
      </template>

      <!-- フッターボタン -->
      <template #[`footer-buttons`]>
        <!-- キャンセルボタン -->
        <v-btn elevation="0" @click="$emit('close')">キャンセル</v-btn>

        <!-- 登録ボタン -->
        <v-btn
          v-if="displayMode == DIALOG_DISPLAY_MODE.REGISTER"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="registerPatient"
        >
          登録
        </v-btn>

        <!-- 更新ボタン -->
        <v-btn
          v-if="displayMode == DIALOG_DISPLAY_MODE.UPDATE"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="updatePatient"
        >
          更新
        </v-btn>
      </template>
    </Dialog>
  </validation-observer>
</template>

<script>
import DateOfBirthPicker from "@/components/molecules/DateOfBirthPicker";
import Disease from "@/components/organisms/Disease";
import Medication from "@/components/organisms/Medication";
import Dialog from "@/components/templates/Dialog";
import {
  DIALOG_DISPLAY_MODE,
  GENDER,
  GENDERS,
  PASSWORD_ENABLE_SYMBOLS,
  PASSWORD_MIN_LENGTH,
  PREFECTURES,
} from "@/const/const";
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import {
  collection,
  doc,
  getDocs,
  query,
  runTransaction,
  serverTimestamp,
  Timestamp,
  where,
} from "firebase/firestore";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  name: "PatientDialog",
  components: {
    Medication,
    Disease,
    DateOfBirthPicker,
    Dialog,
  },
  mixins: [errorHandlerMixin()],
  props: {
    // 患者情報
    patientData: {
      default: null,
    },
    // ダイアログ表示モード
    displayMode: {
      required: true,
    },
  },
  data: () => ({
    PASSWORD_MIN_LENGTH,
    PASSWORD_ENABLE_SYMBOLS,
    // 患者情報
    patient: {
      // 病院ID
      hospitalIds: [],
      // 姓
      lastName: "",
      // 名
      firstName: "",
      // 姓(かな)
      lastNameKana: "",
      // 名(かな)
      firstNameKana: "",
      // 性別
      gender: GENDER.UNSELECTED,
      // 生年月日
      dateOfBirth: "",
      // 郵便番号
      postCode: "",
      // 都道府県
      prefectureCode: "",
      // 市区町村
      municipality: "",
      // 番地
      addressNumber: "",
      // 建物名・部屋番号
      buildingNameAndRoomNumber: "",
      // メールアドレス
      email: "",
      // 電話番号
      phoneNumber: "",
      // パスワード
      password: "",
      // パスワード（確認用）
      passwordConfirm: "",
      // 担当医
      doctorUids: [],
      // メンバー
      memberUids: [],
      // 病気
      diseases: [],
      // 薬
      medications: [],
      // 備考
      note: "",
    },

    // チャットグループ ID
    patientChatGroupIds: [],

    // 選択された日付
    selectedDate: "",

    // 都道府県情報
    PREFECTURES,

    // 表示モード
    DIALOG_DISPLAY_MODE,

    // 性別
    GENDERS,

    // パスワード表示制御
    showPassword: false,
    showPasswordConfirm: false,

    // 病気設定ダイアログ
    diseaseDialog: {
      show: false,
      updateKey: 1, // コンポーネントの再生成を強制するため
    },
    // 薬編集ダイアログ
    medicationDialog: {
      show: false,
      updateKey: 1, // コンポーネントの再生成を強制するため
    },
    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // ログインユーザー
    ...mapState("user", ["selfUser"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // タイトル
    title() {
      let suffix = "";
      switch (this.displayMode) {
        case DIALOG_DISPLAY_MODE.REGISTER:
          suffix = "登録";
          break;
        case DIALOG_DISPLAY_MODE.UPDATE:
          suffix = "編集";
          break;
      }
      return `患者${suffix}`;
    },
    // ダイアログが更新モードか
    isUpdateMode() {
      return this.displayMode == DIALOG_DISPLAY_MODE.UPDATE;
    },
    // 読み取り専用か
    readonly() {
      return this.displayMode == DIALOG_DISPLAY_MODE.DETAIL;
    },
  },
  created() {
    // 登録
    if (this.displayMode == DIALOG_DISPLAY_MODE.REGISTER) {
      // ログインユーザーの所属病院が１つの場合
      if (this.selfUser.hospitalIds.length == 1) {
        // 病院にデフォルト値を設定
        this.patient.hospitalIds.push(this.selfUser.hospitalIds[0]);
      }
    }
    // 編集
    if (this.displayMode == DIALOG_DISPLAY_MODE.UPDATE) {
      this.patient = { ...this.patientData };
      // v-selectに初期表示で都道府県を表示するためNumberに変換
      this.patient.prefectureCode = Number(this.patient.prefectureCode);
      // timestamp型をカレンダーに対応する形式に変換
      this.selectedDate = moment(this.patient.dateOfBirth.toDate()).format("YYYY-MM-DD");
      // 患者のchatGroupsIdを取得
      this.getPatientChatGroupIds();
    }
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // 患者登録
    async registerPatient() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        this.patient.dateOfBirth = this.selectedDate;

        // 患者を追加
        const registerPatientFunction = this.$httpsCallable(this.$functions, "registerpatient");
        await registerPatientFunction(this.patient);

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("close");
      } catch (error) {
        if (error.message === "auth/already-exists") {
          this.showError("メールアドレスが登録済みです");
        } else if (error.message === "auth/invalid-password") {
          this.showError("パスワードの形式が不正です");
        } else {
          this.showError(
            `患者情報（${this.patient.lastName} ${this.patient.firstName}）の登録に失敗しました`
          );
        }
      } finally {
        this.isProcessing = false;
      }
    },
    // 患者情報を更新
    async updatePatient() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // 患者情報
        const {
          uid,
          hospitalIds,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          gender,
          postCode,
          prefectureCode,
          municipality,
          addressNumber,
          buildingNameAndRoomNumber,
          phoneNumber,
          doctorUids,
          diseases,
          medications,
          note,
        } = this.patient;

        let { dateOfBirth } = this.patient;

        // 選択された日付をTimestampのsecondsに変換
        const birthDay = new Date(`${this.selectedDate}T00:00:00Z`);
        dateOfBirth = Timestamp.fromDate(birthDay);

        const db = this.$firestore;

        await runTransaction(db, async (transaction) => {
          const patientDocRef = doc(db, "users", uid);
          const chatGroupDocRef = doc(db, "chatGroups", this.patientChatGroupIds[0]);

          await transaction.get(patientDocRef);
          await transaction.get(chatGroupDocRef);

          // 患者情報を更新
          await transaction.update(patientDocRef, {
            hospitalIds,
            lastName,
            firstName,
            lastNameKana,
            doctorUids,
            firstNameKana,
            gender,
            dateOfBirth,
            postCode,
            prefectureCode,
            municipality,
            addressNumber,
            buildingNameAndRoomNumber,
            phoneNumber,
            diseases,
            medications,
            note,
            updatedUserId: this.selfUser.uid,
            updated: serverTimestamp(),
          });

          // チャットグループ名を更新
          await transaction.update(chatGroupDocRef, {
            name: `${this.patient.lastName} ${this.patient.firstName}`,
            updatedUserId: this.selfUser.uid,
            updated: serverTimestamp(),
          });
        });

        this.decrementRunningApiCount();

        this.showSuccess();

        this.$emit("updatedPatient");
      } catch (error) {
        this.showError(
          `患者情報（${this.patient.lastName} ${this.patient.firstName}）の更新に失敗しました`
        );
      } finally {
        this.isProcessing = false;
      }
    },
    // 成功
    showSuccess() {
      const action = this.displayMode == DIALOG_DISPLAY_MODE.REGISTER ? "登録" : "更新";
      this.$store.dispatch("snackbar/openSnackbar", {
        text: `患者情報（${this.patient.lastName} ${this.patient.firstName}）を${action}しました`,
        color: "success",
      });
    },
    // 病気設定ダイアログ（設定）
    setDiseases(diseases) {
      this.patient.diseases = diseases;
      this.diseaseDialog.updateKey++;
      this.diseaseDialog.show = false;
    },
    // 病気設定ダイアログ（閉じる or キャンセル）
    resetDiseases() {
      this.diseaseDialog.updateKey++;
      this.diseaseDialog.show = false;
    },
    // 薬設定ダイアログ（設定）
    setMedications(medications) {
      this.patient.medications = medications;
      this.medicationDialog.updateKey++;
      this.medicationDialog.show = false;
    },
    // 薬設定ダイアログ（閉じる or キャンセル）
    resetMedications() {
      this.medicationDialog.updateKey++;
      this.medicationDialog.show = false;
    },
    // 患者のチャットグループIDを取得
    async getPatientChatGroupIds() {
      try {
        const q = query(
          collection(this.$firestore, "chatGroups"),
          where("patientUid", "==", this.patient.uid)
        );

        const querySnapshot = await getDocs(q);

        // TODO: 複数のチャットグループに所属する場合はチャットグループ一覧を表示する
        this.patientChatGroupIds.push(querySnapshot.docs[0].id);
      } catch {
        this.showError(MESSAGES.ERRORS.UNEXPECTED);
      }
    },
  },
};
</script>
