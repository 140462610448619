<template>
  <v-card v-if="patient" class="p-4 space-y-4" v-resize="setContentHeight">
    <!-- タイトル -->
    <v-card-title>
      <div>
        {{ displayFullName }}
      </div>

      <v-spacer></v-spacer>

      <!-- 閉じるボタン -->
      <v-btn icon @click="$emit('close')">
        <v-icon large color="primary">mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>

    <!-- タブ -->
    <v-tabs v-model="selectedTab">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab v-for="tab in tabs" :key="tab" class="w-48">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="selectedTab"
      class="mt-4"
      :style="{
        height: `${contentHeight}px`,
      }"
      touchless
    >
      <!-- 患者詳細 -->
      <v-tab-item :key="tabs[0]" class="h-full overflow-hidden" :transition="false">
        <div class="flex h-full space-x-4">
          <!-- 患者情報 -->
          <div class="w-1/2 h-full overflow-y-auto space-y-4 border rounded">
            <!-- 見出し・編集ボタン -->
            <div class="sticky top-0 p-2 flex items-center sub-title">
              <div>患者情報</div>
              <v-spacer></v-spacer>
              <v-btn icon x-small @click="showPatientUpdateDialog">
                <v-icon color="white" small>mdi-pencil</v-icon>
              </v-btn>
            </div>

            <div class="space-y-4 px-4">
              <!-- 病院名 -->
              <div v-if="selfUser?.hospitalIds.length > 1" class="flex">
                <div class="w-64">病院名</div>
                <div class="flex-1">
                  {{ hospitalNames }}
                </div>
              </div>

              <!-- 氏名 -->
              <div class="flex">
                <div class="w-64">氏名</div>
                <div class="flex-1">
                  {{ displayFullName }}
                </div>
              </div>

              <!-- 性別 -->
              <div class="flex">
                <div class="w-64">性別</div>
                <div class="flex-1">{{ GENDER_LABEL[this.patient.gender] }}</div>
              </div>

              <!-- 生年月日 -->
              <div class="flex">
                <div class="w-64">生年月日</div>
                <div class="flex-1">
                  {{ displayDateOfBirth }}（{{ calculateAge(this.patient.dateOfBirth.toDate()) }}
                  歳）
                </div>
              </div>

              <!-- 住所 -->
              <div class="flex">
                <div class="w-64">住所</div>
                <div class="flex-1">
                  {{ `${displayPostCode} ${displayAddress}` }}
                </div>
              </div>

              <!-- メールアドレス -->
              <div class="flex">
                <div class="w-64">メールアドレス</div>
                <div class="flex-1">{{ patient.email }}</div>
              </div>

              <!-- 電話番号 -->
              <div class="flex">
                <div class="w-64">電話番号</div>
                <div class="flex-1">{{ patient.phoneNumber }}</div>
              </div>

              <!-- 登録日 -->
              <div class="flex">
                <div class="w-64">登録日</div>
                <div class="flex-1">{{ displayCreated }}</div>
              </div>

              <!-- 病気 -->
              <div class="flex">
                <div class="w-64">病気</div>
                <div class="flex-1">
                  {{
                    patient.diseases.length > 0
                      ? patient.diseases.map((disease) => disease.name).join(", ")
                      : "現在登録されている病気はありません"
                  }}
                </div>
              </div>

              <!-- 薬 -->
              <div class="flex">
                <div class="w-64">薬</div>
                <div class="flex-1">
                  {{
                    patient.medications.length > 0
                      ? patient.medications.map((medication) => medication.name).join(", ")
                      : "現在登録されている薬はありません"
                  }}
                </div>
              </div>

              <!-- 備考 -->
              <div class="flex">
                <div class="w-64">備考</div>
                <div class="flex-1">
                  <span class="whitespace-pre">{{ patient.note }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 記録一覧 -->
          <div class="w-1/2 h-full overflow-y-auto space-y-4 border rounded">
            <!-- 見出し -->
            <div class="sticky top-0 z-10 p-2 sub-title">記録一覧</div>

            <!-- 月選択 -->
            <div class="mx-20">
              <month-picker
                v-model="recordListDate"
                :maxYearMonth="maxYearMonth"
                :minYearMonth="minYearMonth"
              ></month-picker>
            </div>

            <!-- 記録一覧 -->
            <div class="grow basis-0 overflow-y-scroll mt-0">
              <record-list :date="recordListDate" :patientUid="patientUid"></record-list>
            </div>
          </div>
        </div>
      </v-tab-item>

      <!-- グラフ -->
      <v-tab-item
        :key="tabs[1]"
        :transition="false"
        class="h-full space-y-4 px-20 overflow-y-scroll"
      >
        <!-- 体重 -->
        <div class="border rounded p-2">
          <div class="flex justify-between items-center space-x-4">
            <!-- 見出し -->
            <div class="sub-label w-40 pl-2 font-bold">体重グラフ</div>

            <!-- 月選択 -->
            <div class="w-80">
              <month-picker
                v-model="weightGraphDate"
                :maxYearMonth="maxYearMonth"
                :minYearMonth="minYearMonth"
                :disabled="loadingWeightGraph"
              ></month-picker>
            </div>

            <!-- Dummy -->
            <div class="w-40"></div>
          </div>

          <!-- 体重グラフ -->
          <weight-graph
            :date="weightGraphDate"
            :patientUid="patientUid"
            @update:loading="setLoadingWeightGraph"
          ></weight-graph>
        </div>

        <!-- 血圧 -->
        <div class="border rounded p-2">
          <div class="flex justify-between items-center space-x-4">
            <!-- 見出し -->
            <div class="sub-label w-40 pl-2 font-bold">血圧グラフ</div>

            <!-- 週選択 -->
            <div class="w-80">
              <week-picker
                v-model="bloodPressureGraphDate"
                :maxYearMonth="maxYearMonth"
                :minYearMonth="minYearMonth"
                :disabled="loadingBloodPressure"
              ></week-picker>
            </div>

            <!-- Dummy -->
            <div class="w-40"></div>
          </div>

          <!-- 血圧グラフ -->
          <blood-pressure-graph
            :date="bloodPressureGraphDate"
            :patientUid="patientUid"
            @update:loading="setLoadingBloodPressure"
          ></blood-pressure-graph>
        </div>
      </v-tab-item>

      <!-- 交換日記 -->
      <v-tab-item :key="tabs[2]" :transition="false" class="h-full">
        <div v-if="!isAttending" class="text-center">交換日記は担当医のみが閲覧できます</div>
        <!-- 交換日記を一度開くと、別タブでも既読がついていたので、タブ非表示の場合はv-ifでコンポーネント削除 -->
        <!-- see. http://gitlab/kagoshima-university/heart-diary/general/-/issues/106 -->
        <chat v-else-if="chatGroupId && selectedTab === 2" :groupId="chatGroupId"></chat>
        <div v-else class="text-center">読み込み中</div>
      </v-tab-item>

      <!-- 担当医 -->
      <v-tab-item :key="tabs[3]" :transition="false">
        <!-- 担当医割当ボタン -->
        <div v-show="selectedTab == 3" class="flex justify-end mb-4">
          <v-btn color="primary" elevation="0" @click="showDoctorAssignDialog()">
            担当医割当
          </v-btn>
        </div>
        <responsible-doctor-list
          :key="doctorListUpdateKey"
          :displayMode="DIALOG_DISPLAY_MODE.DETAIL"
          :patientUid="patientUid"
        ></responsible-doctor-list>
      </v-tab-item>

      <!-- メンバー -->
      <v-tab-item :key="tabs[4]" :transition="false">
        <div v-show="selectedTab == 4" class="flex justify-end mb-4 space-x-4">
          <v-btn color="primary" elevation="0" @click="showMemberAssignDialog()"
            >メンバー割当</v-btn
          >
        </div>
        <member-list
          :key="memberListUpdateKey"
          :displayMode="DIALOG_DISPLAY_MODE.DETAIL"
          :patientUid="patientUid"
        ></member-list>
      </v-tab-item>

      <!-- 患者情報更新ダイアログ -->
      <v-dialog
        v-if="patientUpdateDialog.show"
        v-model="patientUpdateDialog.show"
        width="800"
        persistent
        no-click-animation
      >
        <patient-update-dialog
          v-bind="patientUpdateDialog.params"
          @close="patientUpdateDialog.show = false"
          @updatedPatient="patientUpdateDialog.show = false"
        ></patient-update-dialog>
      </v-dialog>

      <!-- 担当医割当ダイアログ -->
      <v-dialog
        v-if="doctorAssignDialog.show"
        v-model="doctorAssignDialog.show"
        width="800"
        persistent
        no-click-animation
      >
        <doctor-assign-dialog
          :patientUid="patientUid"
          @complete="completeDoctorAssign"
          @close="doctorAssignDialog.show = false"
        ></doctor-assign-dialog>
      </v-dialog>

      <!-- メンバー割当ダイアログ -->
      <v-dialog
        v-if="memberAssignDialog.show"
        v-model="memberAssignDialog.show"
        width="800"
        persistent
        no-click-animation
      >
        <member-assign-dialog
          :patientUid="patientUid"
          @complete="completeMemberAssign"
          @close="closeMemberAssignDialog"
        >
        </member-assign-dialog>
      </v-dialog>
    </v-tabs-items>
  </v-card>
</template>

<script>
import MemberAssignDialog from "@/components/dialogs/MemberAssignDialog";
import Patient from "@/components/dialogs/Patient";
import MonthPicker from "@/components/molecules/MonthPicker";
import WeekPicker from "@/components/molecules/WeekPicker";
import BloodPressureGraph from "@/components/organisms/BloodPressureGraph";
import Chat from "@/components/organisms/Chat";
import MemberList from "@/components/organisms/MemberList";
import RecordList from "@/components/organisms/RecordList";
import ResponsibleDoctorList from "@/components/organisms/ResponsibleDoctorList";
import WeightGraph from "@/components/organisms/WeightGraph";
import { DIALOG_DISPLAY_MODE, GENDER_LABEL, MIN_DATE, PREFECTURE_LABEL } from "@/const/const";
import patientMixin from "@/mixins/patientMixin";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import { mapState } from "vuex";
import DoctorAssignDialog from "./DoctorAssignDialog.vue";
export default {
  name: "PatientDetail",
  mixins: [patientMixin()],
  components: {
    PatientUpdateDialog: Patient,
    WeekPicker,
    MonthPicker,
    WeightGraph,
    BloodPressureGraph,
    RecordList,
    Chat,
    DoctorAssignDialog,
    MemberList,
    MemberAssignDialog,
    ResponsibleDoctorList,
  },

  props: {
    // 患者UID
    patientUid: {
      required: true,
      default: null,
    },
  },
  data: () => ({
    // コンテンツの高さ
    contentHeight: 0,

    // 選択されているタブ
    selectedTab: 0,

    // タブ
    tabs: ["患者情報", "体重・血圧グラフ", "交換日記", "担当医", "メンバー情報"],

    // 患者情報更新ダイアログ
    patientUpdateDialog: {
      show: false,
      params: {},
    },

    // 体重グラフの日付
    weightGraphDate: moment().format("YYYYMMDD"),

    // 血圧グラフの日付
    bloodPressureGraphDate: moment().format("YYYYMMDD"),

    // 記録一覧グラフの日付
    recordListDate: moment().format("YYYYMMDD"),
    // 性別のラベル
    GENDER_LABEL,

    // 都道府県のラベル
    PREFECTURE_LABEL,

    // 体重グラフの読み込み状態
    loadingWeightGraph: true,

    // 血圧グラフの読み込み状態
    loadingBloodPressure: true,

    // 選択できる年月の上限
    maxYearMonth: moment().format("YYYYMM"),

    // 担当医割当ダイアログの表示の有無
    doctorAssignDialog: {
      show: false,
    },

    // 担当医一覧を任意のタイミングで更新するためのキー
    doctorListUpdateKey: 1,

    // メンバー割当ダイアログの表示の有無
    memberAssignDialog: {
      show: false,
    },

    // メンバー一覧を任意のタイミングで更新するためのキー
    memberListUpdateKey: 1,

    // chatGroupId
    chatGroupId: null,

    // 表示モード
    DIALOG_DISPLAY_MODE,

    // 選択できる年月の下限
    minYearMonth: MIN_DATE.slice(0, 6),
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    patient() {
      return this.getPatient(this.patientUid);
    },
    // 病院名の表示
    hospitalNames() {
      const hospitals = this.hospitals.filter(
        (hospital) =>
          this.patient.hospitalIds.findIndex((hospitalId) => hospitalId == hospital.id) != -1
      );
      return hospitals.map((hospital) => hospital.name).join("、");
    },

    // 表示用の氏名
    displayFullName() {
      return `${this.patient.lastName} ${this.patient.firstName}
      （${this.patient.lastNameKana} ${this.patient.firstNameKana}）`;
    },

    // 表示用の生年月日
    displayDateOfBirth() {
      return moment(this.patient.dateOfBirth.toDate()).format("YYYY年MM月DD日");
    },

    // 表示用の郵便番号
    displayPostCode() {
      const { postCode } = this.patient;
      return `〒${postCode.substr(0, 3)}-${postCode.substr(3)}`;
    },

    // 表示用の住所
    displayAddress() {
      return `${PREFECTURE_LABEL[this.patient.prefectureCode]} ${this.patient.municipality}
      ${this.patient.addressNumber} ${this.patient.buildingNameAndRoomNumber || ""}`;
    },

    // 表示用の登録日
    displayCreated() {
      const timestamp = this.patient.created.toDate();
      return moment(timestamp).format("YYYY年MM月DD日");
    },

    // 担当医か
    isAttending() {
      return this.patient.doctorUids.includes(this.selfUser.uid);
    },
  },
  mounted() {
    // 患者のchatGroupsIdを取得
    this.getChatGroupId();
  },

  methods: {
    // コンテンツの高さを設定
    setContentHeight() {
      this.contentHeight = window.innerHeight - 280;
    },

    // 患者情報を更新するダイアログを表示
    showPatientUpdateDialog() {
      this.patientUpdateDialog.params = {
        patientData: { uid: this.patientUid, ...this.patient },
        displayMode: DIALOG_DISPLAY_MODE.UPDATE,
      };
      this.patientUpdateDialog.show = true;
    },
    // 体重グラフの読み込み状態を設定
    setLoadingWeightGraph(loading) {
      this.loadingWeightGraph = loading;
    },
    // 血圧グラフの読み込み状態を設定
    setLoadingBloodPressure(loading) {
      this.loadingBloodPressure = loading;
    },
    // 担当医割当ダイアログ表示
    showDoctorAssignDialog() {
      this.doctorAssignDialog.show = true;
    },
    // 担当医割当を完了
    completeDoctorAssign() {
      this.doctorListUpdateKey++;
      this.doctorAssignDialog.show = false;
    },
    // メンバー割当ダイアログを表示
    showMemberAssignDialog() {
      this.memberAssignDialog.show = true;
    },
    // メンバー割当ダイアログを閉じる
    closeMemberAssignDialog() {
      // メンバー編集のみを行う場合もあるため、メンバー割当ダイアログを閉じられた場合もメンバー一覧を更新
      this.completeMemberAssign();
    },
    // メンバー割当を完了
    completeMemberAssign() {
      this.memberListUpdateKey++;
      this.memberAssignDialog.show = false;
    },
    // 患者のチャットグループIDを取得
    async getChatGroupId() {
      try {
        const q = query(
          collection(this.$firestore, "chatGroups"),
          where("patientUid", "==", this.patientUid)
        );
        const querySnapshot = await getDocs(q);

        // TODO: 複数のチャットグループに所属する場合はチャットグループ一覧を表示する
        this.chatGroupId = querySnapshot.docs[0].id;
      } catch (error) {
        // TODO: エラーハンドリング
        console.error("Firestore エラー:", error);
      }
    },
    // 年齢計算
    calculateAge(date) {
      if (!date) return "";
      const dateOfBirth = new Date(date);
      const today = new Date();
      let age = today.getFullYear() - dateOfBirth.getFullYear();
      const monthDiff = today.getMonth() - dateOfBirth.getMonth();

      // 誕生日がまだ来ていない場合、年齢を1減らす
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
        age--;
      }
      return age;
    },
  },
};
</script>

<style>
.sub-title {
  background-color: #39618c !important;
  color: white;
}

.sub-label {
  color: #39618c !important;
}
</style>
