import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

export default function () {
  return {
    methods: {
      async updateFcmToken(uid, skipPermissionCheck = false) {
        try {
          if (!(await this.checkNotificationPermission(skipPermissionCheck))) {
            return;
          }

          // ログインユーザーのFCMトークンを取得
          const fcmTokens = await this.getUserFcmTokens(uid);

          // 最新のトークンを取得
          const fcmToken = await this.getFcmToken();

          // トークンを更新（なければ追加）
          fcmTokens[fcmToken] = { timestamp: serverTimestamp() };
          const userRef = doc(this.$firestore, "users", uid);
          await updateDoc(userRef, { fcmTokens });
        } catch (e) {
          console.error(e);
          // トークン取得時のエラーは握りつぶす
          // this.showError(MESSAGES.ERRORS.UNEXPECTED);
        }
      },

      async deleteFcmToken(uid) {
        try {
          if (!(await this.checkNotificationPermission(true))) {
            return;
          }

          // ログインユーザーのFCMトークンを取得
          const fcmTokens = await this.getUserFcmTokens(uid);

          // 最新のFCMトークンを取得
          const fcmToken = await this.getFcmToken();

          if (fcmToken && fcmTokens[fcmToken]) {
            // FCMトークンを削除
            delete fcmTokens[fcmToken];
            const userRef = doc(this.$firestore, "users", uid);
            await updateDoc(userRef, { fcmTokens });
          }
        } catch (e) {
          console.error(e);
          // トークン取得時のエラーは握りつぶす
          // this.showError(MESSAGES.ERRORS.UNEXPECTED);
        }
      },

      async getFcmToken(retry = false) {
        try {
          return await getToken(getMessaging(), process.env.VUE_APP_FIREBASE_PUBLIC_VAPID_KEY);
        } catch (e) {
          // Firebase Messagingのトークン取得エラーの場合、初回は再取得させる
          // 再取得でもエラーの場合はコンソールにエラーを出力し、エラーをthrowする
          if (e.code === "messaging/token-unsubscribe-failed") {
            if (!retry) {
              return await this.getFcmToken(true);
            } else {
              console.error(e);
              throw e;
            }
          }
        }
      },

      async checkNotificationPermission(skipPermissionCheck = false) {
        if (await isSupported()) {
          const permission = skipPermissionCheck
            ? window.Notification.permission
            : await window.Notification.requestPermission();
          return permission === "granted";
        }
        return false;
      },

      async getUserFcmTokens(uid) {
        const userRef = doc(this.$firestore, "users", uid);
        const userData = (await getDoc(userRef)).data() || {};
        return userData.fcmTokens || {};
      },
    },
  };
}
