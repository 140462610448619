<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <Dialog @close="$emit('close')">
      <!-- タイトル -->
      <template #title> 心不全予兆アラート設定 </template>

      <!-- ヘッダーボタン -->
      <template #header-buttons>
        <!-- リロードボタンの追加 -->
        <v-btn v-if="readonly" icon large @click="reload">
          <v-icon large color="primary">mdi-refresh</v-icon>
        </v-btn>

        <v-btn v-if="readonly" icon large @click="localDisplayMode = DIALOG_DISPLAY_MODE.UPDATE">
          <v-icon large color="primary">mdi-pencil</v-icon>
        </v-btn>
      </template>

      <!-- コンテンツ -->
      <template #contents>
        <!-- 病院 -->
        <div v-if="localSelfUser?.hospitalIds.length > 1" class="flex items-center">
          <div class="w-52">病院<span v-if="!readonly" class="ml-1 red--text">*</span></div>
          <div class="flex justify-end items-center">
            <validation-provider v-slot="{ errors }" name="病院" rules="required">
              <v-select
                class="w-64"
                v-model="localHospitalId"
                :error-messages="errors"
                :items="localSelfUserHospitals"
                dense
                hide-details
                item-text="name"
                item-value="id"
                outlined
                :disabled="!readonly"
              />
            </validation-provider>
          </div>
        </div>

        <div v-for="(setting, key) in settings" :key="key">
          <div class="h-16 flex items-center">{{ setting.label }}</div>
          <div class="pl-8">
            <div class="h-16 flex items-center">
              <div class="w-40 pb-6">
                有効<span v-if="!readonly" class="ml-1 red--text">*</span>
              </div>
              <div class="flex justify-end items-center w-32">
                <v-switch v-model="alertSettings[key].enabled" :disabled="readonly" />
              </div>
            </div>
            <div v-if="alertSettings[key].enabled">
              <div v-for="(field, idx) in setting.fields" :key="idx" class="h-16 flex items-center">
                <div class="w-40 pb-6">
                  {{ field.label }}<span v-if="!readonly" class="ml-1 red--text">*</span>
                </div>
                <div class="flex justify-end items-center w-32">
                  <validation-provider
                    v-slot="{ errors }"
                    :ref="key"
                    :name="field.label"
                    :rules="field.rules"
                  >
                    <v-text-field
                      class="w-60"
                      v-model.number="alertSettings[key][field.model]"
                      :error-messages="errors"
                      dense
                      outlined
                      hide-spin-buttons
                      :min="field.min"
                      :max="field.max"
                      :step="field.step"
                      :suffix="field.suffix"
                      :type="field.type"
                      :disabled="readonly"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="h-16 flex items-center">
                <div class="w-40">条件</div>
                <div class="flex justify-end">
                  {{ setting.labelFn(alertSettings[key]) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- フッターボタン -->
      <template #footer-buttons>
        <div v-if="localDisplayMode == DIALOG_DISPLAY_MODE.UPDATE">
          <v-btn elevation="0" @click="cancel">
            <div>キャンセル</div>
          </v-btn>
        </div>
        <div v-else>
          <v-btn elevation="0" @click="$emit('close')">
            <div>
              {{ localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL ? "閉じる" : "キャンセル" }}
            </div>
          </v-btn>
        </div>
        <v-btn
          v-if="!readonly"
          elevation="0"
          color="primary"
          :disabled="invalid || isProcessing"
          @click="setAlertSetting"
        >
          設定
        </v-btn>
      </template>
    </Dialog>
  </validation-observer>
</template>

<script>
import Dialog from "@/components/templates/Dialog";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { mapActions, mapState } from "vuex";

export default {
  name: "AlertSettingDialog",
  components: {
    Dialog,
  },
  mixins: [errorHandlerMixin()],
  props: {
    displayMode: {
      required: true,
    },
  },
  data() {
    return {
      DIALOG_DISPLAY_MODE,
      localDisplayMode: null,

      localSelfUser: null,

      localHospitalId: null,
      localHospitals: [],

      alertSettings: {
        weightIncrease: {
          enabled: true,
          checkDays: null,
          threshold: null,
        },
        heartRateMax: {
          enabled: true,
          threshold: null,
        },
        restingDyspnea: {
          enabled: true,
        },
      },
      alertSettingsBk: null,

      settings: {
        weightIncrease: {
          label: "体重増加",
          fields: [
            {
              label: "チェック日数",
              model: "checkDays",
              rules: "required|integer|min_value:2|max_value:14",
              min: 2,
              max: 14,
              suffix: "日",
              type: "number",
            },
            {
              label: "しきい値",
              model: "threshold",
              rules: "required|double|min_value:0.1|max_value:50",
              step: 0.1,
              max: 50,
              suffix: "kg",
              type: "number",
            },
          ],
          labelFn: ({ checkDays, threshold }) => {
            const errors = this.$refs.weightIncrease
              ?.map((provider) => provider.errors)
              .filter((errorList) => errorList.length > 0);
            if (errors?.length > 0) {
              return "無効";
            }

            if (!checkDays || !threshold) {
              return "未設定";
            }

            return `過去 ${checkDays} 日と比較して ${threshold} kg 以上の増加`;
          },
        },
        heartRateMax: {
          label: "脈拍",
          fields: [
            {
              label: "しきい値",
              model: "threshold",
              rules: "required|double|min_value:1|max_value:300",
              min: 1,
              max: 300,
              suffix: "bpm",
              type: "number",
            },
          ],
          labelFn: ({ threshold }) => {
            const errors = this.$refs.heartRateMax
              ?.map((provider) => provider.errors)
              .filter((errorList) => errorList.length > 0);
            if (errors?.length > 0) {
              return "無効";
            }

            if (!threshold) {
              return "未設定";
            }

            return `${threshold} bpm 以上`;
          },
        },
        restingDyspnea: {
          label: "安静時息切れ",
          fields: [],
          labelFn: () => "あり",
        },
      },
      // 処理中フラグ
      isProcessing: false,
    };
  },
  computed: {
    readonly() {
      return this.localDisplayMode == DIALOG_DISPLAY_MODE.DETAIL;
    },
    ...mapState("user", ["selfUser"]),
    ...mapState("hospital", ["hospitals"]),
    localSelfUserHospitals() {
      return this.localSelfUser?.hospitalIds.map((hospitalId) =>
        this.localHospitals.find((hospital) => hospital.id == hospitalId)
      );
    },
  },
  watch: {
    async localHospitalId(newVal) {
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });

      const hospital = this.localHospitals.find((hospital) => hospital.id == newVal);
      this.alertSettings = hospital?.alertSettings ?? {
        weightIncrease: { enabled: true, checkDays: null, threshold: null },
        heartRateMax: { enabled: true, threshold: null },
        restingDyspnea: { enabled: true },
      };

      this.alertSettingsBk = JSON.parse(JSON.stringify(this.alertSettings));
    },
  },
  mounted() {
    this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;
    this.localSelfUser = JSON.parse(JSON.stringify(this.selfUser));

    this.localHospitals = JSON.parse(JSON.stringify(this.hospitals));

    if (this.localSelfUserHospitals.length == 0) {
      this.showError(MESSAGES.ERRORS.UNEXPECTED);
      return;
    }

    this.localHospitalId = this.localSelfUserHospitals[0]?.id;
    this.alertSettingsBk = JSON.parse(JSON.stringify(this.alertSettings));
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    async cancel() {
      this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;
      this.alertSettings = JSON.parse(JSON.stringify(this.alertSettingsBk));
    },
    // 登録/更新
    async setAlertSetting() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        if (!this.alertSettings.weightIncrease.enabled) {
          this.alertSettings.weightIncrease.checkDays = null;
          this.alertSettings.weightIncrease.threshold = null;
        }

        if (!this.alertSettings.heartRateMax.enabled) {
          this.alertSettings.heartRateMax.threshold = null;
        }

        const now = serverTimestamp();
        const hospitalDoc = await getDoc(doc(this.$firestore, "hospitals", this.localHospitalId));
        if (hospitalDoc.exists() && hospitalDoc.data()?.alertSettings) {
          // 更新
          await updateDoc(doc(this.$firestore, "hospitals", this.localHospitalId), {
            alertSettings: {
              ...this.alertSettings,
              updatedUid: this.localSelfUser.uid,
              updated: now,
            },
          });
        } else {
          // 登録
          await updateDoc(doc(this.$firestore, "hospitals", this.localHospitalId), {
            alertSettings: {
              ...this.alertSettings,
              createdUid: this.localSelfUser.uid,
              created: now,
              updatedUid: this.localSelfUser.uid,
              updated: now,
            },
          });
        }

        this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;

        this.decrementRunningApiCount();

        // 成功
        this.$store.dispatch("snackbar/openSnackbar", {
          text: `心不全予兆アラート設定を更新しました`,
          color: "success",
        });

        this.localDisplayMode = DIALOG_DISPLAY_MODE.DETAIL;
      } catch (error) {
        this.showError(`心不全予兆アラート設定の更新に失敗しました`);
      } finally {
        this.decrementRunningApiCount();
        this.isProcessing = false;
      }
    },
    // リロード
    async reload() {
      try {
        // ローディング中などの状態を処理する場合はインクリメント
        this.incrementRunningApiCount();

        // store から最新データを取得し、localXXXX に代入
        this.localSelfUser = JSON.parse(JSON.stringify(this.selfUser)); // store からユーザー情報を再取得
        this.localHospitals = JSON.parse(JSON.stringify(this.hospitals)); // store から病院情報を再取得

        const currentHospital = this.localSelfUserHospitals.find(
          (hospital) => hospital?.id === this.localHospitalId
        );
        if (!currentHospital && this.localSelfUserHospitals.length > 0) {
          this.localHospitalId = this.localSelfUserHospitals[0]?.id;
        }

        const hospital = this.localHospitals.find(
          (hospital) => hospital.id == this.localHospitalId
        );
        this.alertSettings = hospital?.alertSettings ?? {
          weightIncrease: { enabled: true, checkDays: null, threshold: null },
          heartRateMax: { enabled: true, threshold: null },
          restingDyspnea: { enabled: true },
        };

        this.alertSettingsBk = JSON.parse(JSON.stringify(this.alertSettings));

        this.decrementRunningApiCount();
      } catch (error) {
        this.showError("最新のデータ取得に失敗しました");
      }
    },
  },
};
</script>
