<template>
  <v-navigation-drawer :mini-variant="localOpend" app permanent width="280">
    <v-list class="p-0" expand height="56px">
      <!-- ハンバーガーメニュー -->
      <v-toolbar color="primary" dark flat height="56px">
        <v-app-bar-nav-icon @click.stop="localOpend = !localOpend"></v-app-bar-nav-icon>
      </v-toolbar>

      <!-- メニュー -->
      <div v-for="(menu, index) in menus" :key="`menu-tooltip-${index}`">
        <!-- サブメニューなし -->
        <v-tooltip
          v-if="!menu.subMenus"
          :nudge-left="4"
          :open-on-click="false"
          :open-on-focus="false"
          :open-on-hover="localOpend"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :key="`menu-${index}`"
              :to="menu.path"
              color="primary"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menu.name }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ menu.name }}</span>
        </v-tooltip>
        <!-- サブメニューあり -->
        <v-list-group
          v-else
          :value="menu.expanded"
          :group="menu.path"
          :key="`group-${index}`"
          color="primary"
        >
          <template #activator>
            <v-tooltip
              :key="`group-tooltip-${index}`"
              :nudge-right="12"
              :open-on-click="false"
              :open-on-focus="false"
              :open-on-hover="localOpend"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon class="mr-3" v-bind="attrs" v-on="on">
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
              </template>
              <span>{{ menu.name }}</span>
            </v-tooltip>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </template>
          <div v-for="(subMenu, subIndex) in menu.subMenus" :key="`sub-menu-tooltip-${subIndex}`">
            <v-tooltip
              :nudge-left="4"
              :open-on-click="false"
              :open-on-focus="false"
              :open-on-hover="localOpend"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  :class="{ 'pl-9': !localOpend }"
                  :key="`sub-menu-${subIndex}`"
                  :to="`${menu.path}${subMenu.path}`"
                >
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{ subMenu.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-0">
                    {{ subMenu.name }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ subMenu.name }}</span>
            </v-tooltip>
          </div>
        </v-list-group>
      </div>
    </v-list>

    <!-- ログアウトボタン -->
    <template v-slot:append>
      <v-tooltip
        :nudge-left="4"
        :open-on-click="false"
        :open-on-focus="false"
        :open-on-hover="localOpend"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item @click="logOut" v-bind="attrs" v-on="on">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </template>
        <span>ログアウト</span>
      </v-tooltip>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import fcmTokenMixin from "@/mixins/fcmTokenMixin";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "SideMenu",
  mixins: [errorHandlerMixin(), fcmTokenMixin()],
  model: {
    prop: "opend",
    event: "change",
  },
  props: {
    // サイドメニューの開閉状態
    opend: {
      default: false,
    },
  },
  data() {
    return {
      menus: [
        { name: "患者一覧", path: "patient-list", icon: "mdi-account" },
        { name: "医師一覧", path: "doctor-list", icon: "mdi-hospital" },
        {
          name: "設定",
          path: "setting",
          icon: "mdi-cog",
        },
      ],
    };
  },
  computed: {
    localOpend: {
      get() {
        return this.opend;
      },
      set(opend) {
        this.$emit("change", opend);
      },
    },
  },
  methods: {
    async logOut() {
      try {
        const auth = getAuth();
        const uid = auth.currentUser?.uid;
        if (uid) {
          // FCMトークンの削除処理
          await this.deleteFcmToken(uid);

          // ログアウト処理
          await signOut(auth);
        }
        this.$router.push("/").catch(() => {});
      } catch (e) {
        this.showError(MESSAGES.ERRORS.UNEXPECTED);
      }
    },
  },
};
</script>
