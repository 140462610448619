<template>
  <v-container class="px-0 space-y-2" v-resize="setPatientListHeight">
    <!-- フィルター -->
    <div>
      <v-card class="py-2" outlined>
        <div class="flex items-center px-4 gap-x-8 gap-y-4 flex-wrap">
          <div class="space-y-2">
            <!-- 病院名 -->
            <div v-if="showHospital" class="flex items-center space-x-4 justify-space-between">
              <div>病院名</div>
              <div class="w-80">
                <v-select v-model="filterCondition.hospitalIds" :items="hospitals" item-value="id" item-text="name"
                  multiple outlined dense hide-details></v-select>
              </div>
            </div>

            <!-- 氏名 -->
            <div class="flex items-center space-x-4 justify-space-between">
              <div>氏名</div>
              <div class="w-80">
                <v-text-field v-model="filterCondition.name" placeholder="氏名 または 氏名（かな）" outlined dense hide-details
                  clearable>
                </v-text-field>
              </div>
            </div>
          </div>

          <div class="space-y-2">
            <!-- 朝の記録 -->
            <div class="flex items-center space-x-4">
              <div>朝の記録</div>
              <v-radio-group v-model="filterCondition.morningRecords" row dense hide-details>
                <v-radio :value="null" label="全て"></v-radio>
                <v-radio :value="true" label="入力済"></v-radio>
                <v-radio :value="false" label="未入力"></v-radio>
              </v-radio-group>
            </div>

            <!-- 夜の記録 -->
            <div class="flex items-center space-x-4">
              <div>夜の記録</div>
              <v-radio-group v-model="filterCondition.nightRecords" row dense hide-details>
                <v-radio :value="null" label="全て"></v-radio>
                <v-radio :value="true" label="入力済"></v-radio>
                <v-radio :value="false" label="未入力"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div class="space-y-2">
            <!-- 心不全予兆 -->
            <div class="flex items-center space-x-4 justify-space-between">
              <div>心不全予兆</div>
              <v-radio-group v-model="filterCondition.signed" row dense hide-details>
                <v-radio :value="null" label="全て"></v-radio>
                <v-radio :value="true" label="あり"></v-radio>
                <v-radio :value="false" label="なし"></v-radio>
              </v-radio-group>
            </div>

            <!-- 未読メッセージ -->
            <div class="flex items-center space-x-4 justify-space-between">
              <div>未読メッセージ</div>
              <v-radio-group v-model="filterCondition.unreadMessages" row dense hide-details>
                <v-radio :value="null" label="全て"></v-radio>
                <v-radio :value="true" label="あり"></v-radio>
                <v-radio :value="false" label="なし"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <!-- 担当患者のみを表示 -->
          <v-checkbox v-model="filterCondition.assignedPatientsOnly" label="担当患者のみを表示" dense hide-details></v-checkbox>

          <v-spacer></v-spacer>

          <!-- リセット -->
          <v-btn color="primary" elevation="0" outlined @click="resetFilterCondition">
            リセット
          </v-btn>
        </div>
      </v-card>
    </div>

    <!-- 患者一覧 -->
    <div class="flex items-center justify-space-between">
      <!-- 表示件数 -->
      <div>全{{ patients.length }}件中/{{ filteredPatient.length }}件を表示</div>

      <!-- 患者登録ボタン -->
      <v-btn color="primary" elevation="0" @click="showPatientRegisterDialog">患者登録</v-btn>
    </div>

    <div class="w-full">
      <v-data-table ref="patient-list" :headers="headers" :items="filteredPatient" :height="patientListHeight"
        :items-per-page="-1" sort-by="nameKana" fixed-header @click:row="(item) => {
          showPatientDetailDialog(item.uid);
        }
          " hide-default-footer>
        <!-- 朝の記録 -->
        <template #[`item.inputedMorningRecord`]="{ item }">
          <v-chip v-if="item.inputedMorningRecord" color="primary" dark>入力済</v-chip>
          <v-chip v-else color="grey lighten-4">未入力</v-chip>
        </template>

        <!-- 夜の記録 -->
        <template #[`item.inputedNightRecord`]="{ item }">
          <v-chip v-if="item.inputedNightRecord" color="primary" dark>入力済</v-chip>
          <v-chip v-else color="grey lighten-4">未入力</v-chip>
        </template>

        <!-- 心不全予兆 -->
        <template #[`item.signed`]="{ item }">
          <v-icon v-if="signed(item.todayRecord?.signs)" color="red">mdi-alert</v-icon>
          <span v-else>なし</span>
        </template>

        <!-- 未読メッセージ数 -->
        <template #[`item.unreadMessageCount`]="{ item }">
          <span v-if="!isAssigned(item.doctorUids)"> - </span>
          <v-chip v-else-if="item.unreadMessageCount > 0" color="red" dark>
            {{ item.unreadMessageCount }}
          </v-chip>
          <span v-else>0</span>
        </template>
      </v-data-table>

      <!-- 患者登録ダイアログ -->
      <v-dialog v-if="patientDialog.show" v-model="patientDialog.show" width="800" persistent no-click-animation>
        <patient-dialog v-bind="patientDialog.params" @complete="patientDialog.show = false"
          @close="patientDialog.show = false"></patient-dialog>
      </v-dialog>

      <!-- 患者詳細ダイアログ -->
      <v-dialog v-if="patientDetail.show" v-model="patientDetail.show" width="1400" persistent no-click-animation>
        <patient-detail-dialog v-bind="patientDetail.params"
          @close="patientDetail.show = false"></patient-detail-dialog>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Patient from "@/components/dialogs/Patient";
import PatientDetail from "@/components/dialogs/PatientDetail";
import { DIALOG_DISPLAY_MODE } from "@/const/const";
import { mapState } from "vuex";

export default {
  name: "PatientList",
  components: { PatientDialog: Patient, PatientDetailDialog: PatientDetail },
  data: () => ({
    // 患者一覧の高さ
    patientListHeight: "100%",

    // フィルター条件
    filterCondition: {
      // NOTE: 初期値は resetFilterCondition に定義すること
    },

    // 患者登録ダイアログの表示の有無
    patientDialog: {
      show: false,
      params: {},
    },

    // 患者詳細ダイアログ表示の有無
    patientDetail: {
      show: false,
      params: {
        // 患者uid
        patientUid: null,
      },
    },
  }),
  computed: {
    // ログインユーザー情報
    ...mapState("user", ["selfUser"]),
    // 病院情報
    ...mapState("hospital", ["hospitals"]),
    // 患者情報
    ...mapState("patient", ["patients"]),

    // headerの表示
    headers() {
      const headers = [
        { text: "氏名", value: "name", width: "20%" },
        { text: "氏名（かな）", value: "nameKana", width: "20%" },
        { text: "朝の記録", value: "inputedMorningRecord", width: "15%", align: "center" },
        { text: "夜の記録", value: "inputedNightRecord", width: "15%", align: "center" },
        { text: "心不全予兆", value: "signed", width: "15%", align: "center" },
        { text: "未読メッセージ数", value: "unreadMessageCount", width: "15%", align: "center" },
      ];
      // 病院名列はユーザーが複数の病院に所属している場合のみ表示
      if (this.showHospital) {
        headers.unshift({ text: "病院名", value: "hospitalNames" });
      }
      return headers;
    },
    // ユーザーが複数の病院に所属しているかどうか
    showHospital() {
      return this.selfUser?.hospitalIds.length > 1;
    },

    // フィルター済みの患者情報
    filteredPatient() {
      // 一覧の表示形式に合わせて整形
      let filteredPatients = this.patients.map((patient) => {
        // 病院名の取得
        const hospitalNames = patient.hospitalIds.map((hospitalId) => {
          const hospital = this.hospitals.find((hospital) => hospital.id === hospitalId);
          return hospital?.name || "";
        });
        // 朝と夜の記録の入力状況を判定
        const { todayRecord } = patient;

        if (todayRecord) {
          const { morning, night } = todayRecord;

          // 朝の血圧・脈拍が全て入力されているかどうか
          patient.inputedMorningRecord = !!(
            morning?.systolicPressure &&
            morning?.diastolicPressure &&
            morning?.heartRate
          );

          // 夜の血圧・脈拍が全て入力されているかどうか
          patient.inputedNightRecord = !!(
            night?.systolicPressure &&
            night?.diastolicPressure &&
            night?.heartRate
          );
        } else {
          patient.inputedMorningRecord = false;
          patient.inputedNightRecord = false;
        }

        return {
          ...patient,
          hospitalNames: hospitalNames,
          name: `${patient.lastName} ${patient.firstName}`,
          nameKana: `${patient.lastNameKana} ${patient.firstNameKana}`,
          signed: !!this.signed(patient.todayRecord?.signs),
        };
      });

      // フィルター
      const {
        hospitalIds = [],
        name,
        morningRecords,
        nightRecords,
        signed,
        unreadMessages,
        assignedPatientsOnly,
      } = this.filterCondition;

      // 病院
      if (hospitalIds.length > 0) {
        filteredPatients = filteredPatients.filter((patient) =>
          patient.hospitalIds.some((hospitalId) => hospitalIds.includes(hospitalId))
        );
      }

      // 氏名・かな
      if (name) {
        filteredPatients = filteredPatients.filter(
          (patient) => patient.name.includes(name) || patient.nameKana.includes(name)
        );
      }

      // 朝の記録
      if (morningRecords != null) {
        filteredPatients = filteredPatients.filter(
          (patient) => patient.inputedMorningRecord == morningRecords
        );
      }

      // 夜の記録
      if (nightRecords != null) {
        filteredPatients = filteredPatients.filter(
          (patient) => patient.inputedNightRecord == nightRecords
        );
      }

      // 心不全予兆
      if (signed != null) {
        filteredPatients = filteredPatients.filter((patient) => patient.signed == signed);
      }

      // 未読メッセージ
      if (unreadMessages != null) {
        filteredPatients = filteredPatients.filter(
          (patient) => !!patient.unreadMessageCount == unreadMessages
        );
      }

      // 担当患者のみを表示
      if (assignedPatientsOnly) {
        filteredPatients = filteredPatients.filter((patient) =>
          this.isAssigned(patient.doctorUids)
        );
      }

      return filteredPatients;
    },
  },
  mounted() {
    // フィルター条件をリセット
    this.resetFilterCondition();

    // アラートメールからの遷移時
    if (this.$route.query.from === "alert") {
      this.filterCondition.signed = true;
      this.filterCondition.assignedPatientsOnly = true;
    }

  },
  methods: {
    // 患者一覧の高さを設定
    setPatientListHeight() {
      this.patientListHeight =
        window.innerHeight - this.$refs["patient-list"].$el.getBoundingClientRect().top - 70;
    },
    // 患者登録ダイアログを表示
    showPatientRegisterDialog() {
      this.patientDialog.params = {
        displayMode: DIALOG_DISPLAY_MODE.REGISTER,
      };
      this.patientDialog.show = true;
    },
    // 患者詳細ダイアログを表示
    showPatientDetailDialog(patientUid) {
      this.patientDetail.params = { patientUid };
      this.patientDetail.show = true;
    },
    // フィルター条件をリセット
    resetFilterCondition() {
      this.filterCondition = {
        // 病院
        hospitalIds: [],
        // 氏名・かな
        name: "",
        // 朝の記録
        morningRecords: null,
        // 夜の記録
        nightRecords: null,
        // 心不全予兆
        signed: null,
        // 未読メッセージ
        unreadMessages: null,
        // 担当患者のみを表示
        assignedPatientsOnly: null,
      };
    },
    signed(signs) {
      return (
        signs?.heartRateMaxMorning.signed
        || signs?.heartRateMaxNight.signed
        || signs?.restingDyspnea.signed
        || signs?.weightIncrease.signed
      );
    },
    isAssigned(uids) {
      return uids.includes(this.selfUser.uid);
    },
  },
};
</script>

<style lang="scss" scoped>
// 患者一覧
.v-data-table {
  @apply border;
}

// 一覧の行
::v-deep tbody>tr>td {
  @apply cursor-pointer;
}

::v-deep tbody>tr:last-child>td {
  @apply border-b;
  @apply truncate;
}
</style>
