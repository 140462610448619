// ラベル種別
export const LABEL_TYPES = {
  patient: {
    label: "患者",
    color: " pink darken-1",
    textColor: "white",
  },
  doctor: {
    label: "医師",
    color: "indigo darken-1",
    textColor: "white",
  },
  family: {
    label: "家族",
    color: "green darken-1",
    textColor: "white",
  },
  familyDoctor: {
    label: "かかりつけ医",
    color: "blue darken-1",
    textColor: "white",
  },
  nurse: {
    label: "看護師",
    color: "pink accent-1",
    textColor: "white",
  },
  careManager: {
    label: "ケアマネージャー",
    color: "teal  darken-1",
    textColor: "white",
  },
  careWorker: {
    label: "介護士",
    color: "cyan darken-1",
    textColor: "white",
  },
  other: {
    label: "その他",
    color: "grey",
    textColor: "white",
  },
};

// 種別に対応するラベル種別
export const TYPE_TO_LABEL_TYPES = {
  patient: ["patient"],
  doctor: ["doctor"],
  member: ["family", "familyDoctor", "nurse", "careManager", "careWorker", "other"],
};

// 性別
export const GENDER = {
  UNSELECTED: 0,
  MALE: 1,
  FEMALE: 2,
};

export const GENDER_LABEL = {
  0: "無回答",
  1: "男性",
  2: "女性",
};

export const GENDERS = [
  {
    value: GENDER.MALE,
    label: GENDER_LABEL[1],
  },
  {
    value: GENDER.FEMALE,
    label: GENDER_LABEL[2],
  },
  {
    value: GENDER.UNSELECTED,
    label: GENDER_LABEL[0],
  },
];

// 都道府県
export const PREFECTURE = {
  HOKKAIDO: 1,
  AOMORI: 2,
  IWATE: 3,
  MIYAGI: 4,
  AKITA: 5,
  YAMAGATA: 6,
  FUKUSHIMA: 7,
  IBARAKI: 8,
  TOCHIGI: 9,
  GUNMA: 10,
  SAITAMA: 11,
  CHIBA: 12,
  TOKYO: 13,
  KANAGAWA: 14,
  NIIGATA: 15,
  TOYAMA: 16,
  ISHIKAWA: 17,
  FUKUI: 18,
  YAMANASHI: 19,
  NAGANO: 20,
  GIFU: 21,
  SHIZUOKA: 22,
  AICHI: 23,
  MIE: 24,
  SHIGA: 25,
  KYOTO: 26,
  OSAKA: 27,
  HYOGO: 28,
  NARA: 29,
  WAKAYAMA: 30,
  TOTTORI: 31,
  SHIMANE: 32,
  OKAYAMA: 33,
  HIROSHIMA: 34,
  YAMAGUCHI: 35,
  TOKUSHIMA: 36,
  KAGAWA: 37,
  EHIME: 38,
  KOCHI: 39,
  FUKUOKA: 40,
  SAGA: 41,
  NAGASAKI: 42,
  KUMAMOTO: 43,
  OITA: 44,
  MIYAZAKI: 45,
  KAGOSHIMA: 46,
  OKINAWA: 47,
};

export const PREFECTURE_LABEL = {
  1: "北海道",
  2: "青森県",
  3: "岩手県",
  4: "宮城県",
  5: "秋田県",
  6: "山形県",
  7: "福島県",
  8: "茨城県",
  9: "栃木県",
  10: "群馬県",
  11: "埼玉県",
  12: "千葉県",
  13: "東京都",
  14: "神奈川県",
  15: "新潟県",
  16: "富山県",
  17: "石川県",
  18: "福井県",
  19: "山梨県",
  20: "長野県",
  21: "岐阜県",
  22: "静岡県",
  23: "愛知県",
  24: "三重県",
  25: "滋賀県",
  26: "京都府",
  27: "大阪府",
  28: "兵庫県",
  29: "奈良県",
  30: "和歌山県",
  31: "鳥取県",
  32: "島根県",
  33: "岡山県",
  34: "広島県",
  35: "山口県",
  36: "徳島県",
  37: "香川県",
  38: "愛媛県",
  39: "高知県",
  40: "福岡県",
  41: "佐賀県",
  42: "長崎県",
  43: "熊本県",
  44: "大分県",
  45: "宮崎県",
  46: "鹿児島県",
  47: "沖縄県",
};

export const PREFECTURES = Object.keys(PREFECTURE).map((key) => ({
  value: PREFECTURE[key],
  label: PREFECTURE_LABEL[PREFECTURE[key]],
}));

// ダイアログ表示モード
export const DIALOG_DISPLAY_MODE = {
  REGISTER: 1, // 登録
  UPDATE: 2, // 編集
  DETAIL: 3, // 詳細
};

// スナックバータイムアウト値
export const SNACKBAR_TIMEOUT = 5000;

// 患者記録の最小管理対象日付
export const MIN_DATE = "20140101";

// パスワード最小文字数
export const PASSWORD_MIN_LENGTH = 8;

// パスワード使用可能記号
export const PASSWORD_ENABLE_SYMBOLS = ' ! @ # $ % ^ & * ( ) , . ? " : { } | < >';
