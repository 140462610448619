<template>
  <v-menu
    ref="showCalendar"
    v-model="showCalendar"
    :close-on-content-click="false"
    top
    min-width="auto"
  >
    <!-- 選択日付表示 -->
    <template v-slot:activator="{ on }">
      <div class="flex items-center">
        <div class="flex-shrink-0 w-36">
          <validation-provider v-slot="{ errors }" name="生年月日" rules="required">
            <v-text-field v-if="readonly" :value="formattedDate" dense outlined disabled>
            </v-text-field>
            <v-text-field
              v-else
              :value="formattedDate"
              readonly
              outlined
              dense
              v-on="on"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </div>
        <div class="ml-2 pb-6" v-if="localSelectedDate">（{{ age }} 歳）</div>
      </div>
    </template>
    <!-- カレンダー表示 -->
    <v-date-picker
      v-model="localSelectedDate"
      locale="jp-ja"
      :day-format="(date) => new Date(date).getDate()"
      :active-picker.sync="activePicker"
      :max="maxDate"
      min="1900-01-01"
      no-title
      scrollable
      @input="showCalendar = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { ValidationProvider } from "vee-validate";

export default {
  name: "DateOfBirthPicker",
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      default: "",
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCalendar: false,
      localSelectedDate: this.value ? this.value : null,
      activePicker: null,
    };
  },
  computed: {
    age() {
      return this.localSelectedDate ? this.calculateAge(this.localSelectedDate) : "";
    },
    formattedDate() {
      return this.localSelectedDate ? this.formatDate(this.localSelectedDate) : "";
    },
    maxDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10);
    },
  },
  watch: {
    showCalendar(val) {
      if (val) {
        this.$nextTick(() => {
          this.activePicker = "YEAR";
        });
      }
    },
    localSelectedDate(newDate) {
      this.$emit("input", newDate);
    },
  },
  methods: {
    // 日付フォーマット
    formatDate(date) {
      if (!date) return null;
      return moment(date).format("YYYY年M月D日");
    },
    // 年齢計算
    calculateAge(date) {
      const dateOfBirth = new Date(date);
      const today = new Date();
      let age = today.getFullYear() - dateOfBirth.getFullYear();
      const monthDiff = today.getMonth() - dateOfBirth.getMonth();

      // 誕生日がまだ来ていない場合、年齢を1減らす
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
        age--;
      }
      return age;
    },
  },
};
</script>
