import Error404 from "@/components/pages/404";
import DoctorList from "@/components/pages/DoctorList";
import Login from "@/components/pages/Login";
import PasswordResetting from "@/components/pages/PasswordResetting";
import PasswordResettingComplete from "@/components/pages/PasswordResettingComplete.vue";
import PasswordResettingConfirm from "@/components/pages/PasswordResettingConfirm.vue";
import PatientList from "@/components/pages/PatientList";
import Setting from "@/components/pages/Setting";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/login",
    component: Login,
    meta: { title: "ログイン" },
  },
  {
    path: "/patient-list",
    name: "/patient-list",
    component: PatientList,
    meta: { requiresAuth: true, title: "患者一覧" },
  },
  {
    path: "/doctor-list",
    name: "/doctor-list",
    component: DoctorList,
    meta: { requiresAuth: true, title: "医師一覧" },
  },
  {
    path: "/setting",
    name: "/setting",
    component: Setting,
    meta: { requiresAuth: true, title: "設定" },
  },
  {
    path: "/password-resetting",
    name: "/password-resetting",
    component: PasswordResetting,
    meta: { title: "パスワード再設定" },
  },
  {
    path: "/password-resetting-confirm/:codeId",
    name: "/password-resetting-confirm",
    component: PasswordResettingConfirm,
    meta: { title: "パスワード再設定" },
    props: (route) => ({ codeId: route.params.codeId }),
  },
  {
    path: "/password-resetting-complete",
    name: "/password-resetting-complete",
    component: PasswordResettingComplete,
    meta: { title: "パスワード再設定" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "/404",
    component: Error404,
    meta: { isErrorPage: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  // ログイン画面を表示する際にログイン済みの場合は患者一覧画面にリダイレクト
  if (to.path == "/" && (await getCurrentUser())) {
    next("/patient-list");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
