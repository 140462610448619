import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";

import { mapActions, mapState } from "vuex";

export default function () {
  return {
    computed: {
      ...mapState("user", ["selfUser"]),
      ...mapState("patient", ["patients"]),
    },
    methods: {
      ...mapActions("patient", [
        "addPatient",
        "deletePatient",
        "setPatientInfo",
        "setTodayRecord",
        "setUnreadMessageCount",
        "setLastReadDatetime",
      ]),
      ...mapActions("unsubscribe", ["addUnsubscribe"]),

      // 患者情報の購読処理
      subscribePatients() {
        try {
          const db = this.$firestore;

          // typeがpatientかつログインユーザーと同じ病院に所属しているユーザーを取得
          const q = query(
            collection(db, "users"),
            where("type", "==", "patient"),
            where("hospitalIds", "array-contains-any", this.selfUser.hospitalIds)
          );

          return onSnapshot(
            q,
            (snapshot) => {
              snapshot.docChanges().forEach(async (change) => {
                const patientId = change.doc.id;
                const patientInfo = change.doc.data();

                switch (change.type) {
                  // 患者追加
                  case "added": {
                    // 患者のチャットグループを取得
                    const chatDocSnap = await getDocs(
                      query(collection(db, "chatGroups"), where("patientUid", "==", patientId))
                    );
                    const chatGroupId = chatDocSnap?.docs[0].id;

                    this.addPatient({ uid: patientId, patient: patientInfo });
                    await this._subscribePatientRecords(patientId, chatGroupId);
                    break;
                  }
                  // 患者更新
                  case "modified": {
                    this.setPatientInfo({ uid: patientId, patientInfo });
                    break;
                  }

                  // 患者削除
                  case "removed":
                    // TODO ストア側の患者削除処理実装
                    this.deletePatient({ uid: patientInfo.id });
                    break;
                }
              });
            },
            () => {
              location.reload();
            }
          );
        } catch (error) {
          // エラーハンドリング
          console.error("Firestore エラー:", error);
        }
      },

      // 患者情報を取得
      getPatient(uid) {
        return this.patients.find((patient) => patient.uid == uid);
      },

      // 患者情報、メッセージ、最終既読日時を購読開始
      async _subscribePatientRecords(patientId, chatGroupId) {
        try {
          const db = this.$firestore;

          // 記録購読処理
          const recordUnsubscribe = onSnapshot(
            doc(db, "users", patientId, "records", moment().format("YYYYMM")),
            (snapshot) => {
              const todayRecord = snapshot.data()?.[moment().format("DD")];

              this.setTodayRecord({
                uid: patientId,
                todayRecord,
              });
            },
            () => {
              location.reload();
            }
          );
          this.addUnsubscribe({ unsubscribe: recordUnsubscribe });

          const messageRef = collection(db, "chatGroups", chatGroupId, "messages");
          const messageQuery = query(messageRef, limit(1), orderBy("modified", "desc"));

          // メッセージ購読処理
          const messageSubscribe = onSnapshot(
            messageQuery,
            async () => {
              const lastReadDatetime = this.getPatient(patientId).lastReadDatetime;
              if (lastReadDatetime) {
                // メッセージが追加されるたびに未読メッセージ数を更新
                const unreadMessageCount = await getCountFromServer(
                  query(
                    messageRef,
                    where("uid", "!=", this.selfUser.uid),
                    where("datetime", ">", lastReadDatetime),
                    where("deleted", "==", null)
                  )
                );
                this.setUnreadMessageCount({
                  uid: patientId,
                  unreadMessageCount: unreadMessageCount.data().count,
                });
              }
            },
            () => {
              location.reload();
            }
          );
          this.addUnsubscribe({ unsubscribe: messageSubscribe });

          // 最終既読日時購読処理
          const lastReadUnsubscribe = onSnapshot(
            doc(db, "chatGroups", chatGroupId, "lastReadDatetimes", this.selfUser.uid),
            async (snapshot) => {
              if (snapshot.exists()) {
                // 最終既読日時が更新されるたびに、未読メッセージ数・最終既読日時を更新
                const unreadMessageCount = await getCountFromServer(
                  query(
                    messageRef,
                    where("uid", "!=", this.selfUser.uid),
                    where("datetime", ">", snapshot.data().datetime)
                  )
                );
                this.setUnreadMessageCount({
                  uid: patientId,
                  unreadMessageCount: unreadMessageCount.data().count,
                });
                this.setLastReadDatetime({
                  uid: patientId,
                  lastReadDatetime: snapshot.data().datetime,
                });
              } else {
                this.setUnreadMessageCount({
                  uid: patientId,
                  unreadMessageCount: 0,
                });
              }
            },
            () => {
              location.reload();
            }
          );
          this.addUnsubscribe({ unsubscribe: lastReadUnsubscribe });
        } catch (error) {
          // TODO エラー表示処理
        }
      },
    },
  };
}
