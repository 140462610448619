<template>
  <v-card class="p-4 space-y-4" :max-height="maxHeight" v-resize="setDialogMaxHeight">
    <v-card-title class="space-x-4">
      <!-- タイトル -->
      <div>
        <slot name="title"> </slot>
      </div>

      <v-spacer></v-spacer>

      <!-- その他ボタン -->
      <slot name="header-buttons"> </slot>

      <!-- 閉じるボタン -->
      <v-btn icon @click="$emit('close')">
        <v-icon large color="primary">mdi-close-circle</v-icon>
      </v-btn>
    </v-card-title>

    <!-- コンテンツ -->
    <v-card-text class="pt-4 px-4">
      <slot name="contents"> </slot>
    </v-card-text>

    <!-- ボタン -->
    <v-card-actions class="flex justify-end space-x-4">
      <slot name="footer-buttons"> </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  // ダイアログテンプレート
  name: "DialogTemplate",
  data: () => ({
    // ダイアログの最大の高さ
    maxHeight: null,
  }),
  mounted() {
    // ダイアログの最大の高さを設定
    this.setDialogMaxHeight();
  },
  methods: {
    // ダイアログの最大の高さを設定
    setDialogMaxHeight() {
      this.maxHeight = window.innerHeight - 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.v-card__actions {
  padding: 0 !important;
}
</style>
