const getters = {};

const getDefaultState = () => {
  return {
    // メンバー情報
    members: [],
  };
};

const state = getDefaultState();

const mutations = {
  // メンバー情報を追加
  // 既に存在する場合は上書き更新
  addMember: (state, payload) => {
    const index = state.members.findIndex((member) => member.uid == payload.uid);

    if (index == -1) {
      state.members.push(payload);
    } else {
      state.members.splice(index, 1, payload);
    }
  },

  // メンバー情報を削除
  deleteMember: (state, payload) => {
    const index = state.members.findIndex((member) => member.uid == payload.uid);
    if (index != -1) {
      state.members.splice(index, 1);
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addMember: ({ commit }, member) => {
    commit("addMember", member);
  },
  deleteMember: ({ commit }, member) => {
    commit("deleteMember", member);
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
